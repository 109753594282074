import { useMemo } from "react";
import { Link, useParams } from "react-router-dom";
import { ArrowLeftCircle, ChevronDown, ChevronUp } from "react-feather";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { CustomButton } from "@control-tower/aerq-ui-library";
import { Router } from "constant/router";
import { Popover, Search } from "components";
import { useNavigate } from "utils/useNavigate";
import { useGetAllAcmInstanceQuery, useGetAllInstanceQuery } from "../services";
import { InstanceType } from "../types";
import { usePermissions } from "common";
import roles from "constant/roles";

export function InstanceSelectorBody({
  data = [],
  instanceId,
  close,
}: {
  data: InstanceType[] | undefined;
  close: () => void;
  instanceId: string | undefined;
}) {
  const navigateTo = useNavigate();

  const options = useMemo(
    () => data?.map(({ prettyName, instanceID, type }) => ({ label: prettyName, id: instanceID, type })),
    [data]
  );

  return (
    <Box width={236} p={1}>
      <Stack spacing={1}>
        <Typography fontWeight={500} color="textPrimary">
          Switch context
        </Typography>
        <Search
          onChange={(option) => {
            if (option) {
              close();
              const detailsRoute =
                option?.type === "acm"
                  ? Router.acmInstance().services(option.id)
                  : Router.instance().services(option.id);
              navigateTo("push", detailsRoute);
            }
          }}
          options={options}
          placeholder="Search instance"
        />
        {instanceId && (
          <Link to={Router.overview().instances()} style={{ textDecoration: "none" }}>
            <CustomButton fullWidth startIcon={<ArrowLeftCircle />}>
              All instances
            </CustomButton>
          </Link>
        )}
      </Stack>
    </Box>
  );
}

export function InstanceSelector() {
  const { instanceId } = useParams();
  const [canCreateAcmInstance] = usePermissions(roles.createACMInstance);
  const [canViewAcmInstance] = usePermissions(roles.vtrDeveloperAdmin);

  const { data = [] } = useGetAllInstanceQuery();
  const { data: acmData = [] } = useGetAllAcmInstanceQuery(undefined, {
    skip: !(canCreateAcmInstance || canViewAcmInstance),
  });

  const label = useMemo(() => {
    return data?.find((instance) => instance.instanceID === instanceId)?.prettyName || "All instances";
  }, [data, instanceId]);

  return (
    <Popover
      sx={{ mt: 1 }}
      trigger={(open, isOpen) => (
        <CustomButton
          onClick={open}
          size="large"
          sx={{ justifyContent: "space-between", mb: 2 }}
          endIcon={isOpen ? <ChevronUp /> : <ChevronDown />}
          fullWidth
          variant="outlined"
        >
          {label}
        </CustomButton>
      )}
    >
      {(close) => <InstanceSelectorBody close={close} instanceId={instanceId} data={[...data, ...acmData]} />}
    </Popover>
  );
}
