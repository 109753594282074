import { ServiceList } from "features";
import { useGetServiceCatalogQuery } from "services/settings.service";

export function General() {
  const { data = [] } = useGetServiceCatalogQuery();

  const serviceList = data.map((s) => ({
    name: s.serviceName,
    type: s.type,
    version: [...s.versions].pop() || "N/A",
  }));

  return (
    <div data-testid="general-container">
      <ServiceList services={serviceList} />
    </div>
  );
}
