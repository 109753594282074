import React, { ReactNode, useState } from "react";
import CustomButton, {
  CustomButtonProps,
} from "@control-tower/aerq-ui-library/dist/components/CustomButton/CustomButton";

export function LoadButton({
  action,
  children,
  loadingLabel = "Loading...",
  buttonProps = {},
  disabled,
  ...props
}: Readonly<{
  action: () => Promise<any>;
  children: ReactNode;
  disabled?: boolean;
  loadingLabel?: string;
  buttonProps?: Omit<CustomButtonProps, "onClick">;
}>) {
  const [loading, setLoading] = useState(false);

  async function handleAction() {
    setLoading(true);
    await action();
    setLoading(false);
  }

  return (
    <CustomButton {...props} {...buttonProps} disabled={loading || disabled} onClick={handleAction}>
      {!loading ? children : loadingLabel}
    </CustomButton>
  );
}
