import { CustomButton } from "@control-tower/aerq-ui-library";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { LinkTab } from "components";
import { Router } from "constant/router";
import { useRef } from "react";
import { Outlet } from "react-router-dom";

export function Settings() {
  const ref = useRef<HTMLButtonElement | null>(null);

  return (
    <div>
      <Typography paragraph variant="h1">
        Settings
      </Typography>
      <Stack direction="row" justifyContent="space-between" spacing={2} mb={4}>
        <Stack direction="row" spacing={2}>
          <LinkTab to={Router.settings().general()} label="General" />
          <LinkTab to={Router.settings().access()} label="Access" />
        </Stack>
        <CustomButton ref={ref}>Save</CustomButton>
      </Stack>
      <Outlet context={ref} />
    </div>
  );
}
