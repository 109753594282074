import endpoints from "constant/endpoints";
import { api } from "lib";
import { AvailableService, SshType, CreateCredentialType } from "features";

export type Services = AvailableService[];

export enum AuthMethod {
  PASSWORD = "PASSWORD",
  SSHKEY = "SSHKEY",
}

export interface Settings extends CreateCredentialType {
  userId?: string;
  sshKeys: SshType[];
  rootPassword: string;
  preferredAuthMethod?: AuthMethod;
}

export const settingsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getServiceCatalog: builder.query<Services, void>({
      query: () => ({
        url: endpoints.settings.serviceCatalog,
      }),
      providesTags: (result = []) => {
        return [
          ...result.map(({ serviceName }) => ({ type: "Catalog", serviceName } as const)),
          { type: "Catalog" as const, id: "LIST" },
        ];
      },
    }),
    getSettings: builder.query<Settings, void>({
      query: () => ({
        url: endpoints.settings.settings,
      }),
      providesTags: ["Settings"],
    }),
    updateSettings: builder.mutation<void, Partial<Settings>>({
      query: (body) => ({
        url: endpoints.settings.settings,
        method: "POST",
        body,
      }),
    }),
  }),
});

export const { useGetServiceCatalogQuery, useGetSettingsQuery, useUpdateSettingsMutation } = settingsApi;
