import { useGetAllInstanceQuery } from "features";
import { ManageInstance } from "./manageInstances";

export function VtrInstances() {
  const { data: instanceList = [], isFetching, refetch } = useGetAllInstanceQuery();

  const onRefreshInstanceList = () => {
    refetch();
  };

  return <ManageInstance instanceList={instanceList} isFetching={isFetching} onRefreshList={onRefreshInstanceList} />;
}
