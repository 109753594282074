import airportsJson from "./airports.json";

export const COORDINATE_VALIDATION = new RegExp(/^-?([1-8]?[1-9]|[1-9]0)\.{1}\d{1,6}$/);
export const TIME_VALIDATION = new RegExp(/^(?:[01][0-9]|2[0-3]):[0-5][0-9](?::[0-5][0-9])?$/);
export const AIRPORTS = airportsJson;

export interface AIRPORT_TYPES {
  code: string;
  lat: string;
  lon: string;
  name: string;
  city: string;
  state: string | null;
  country: string;
  woeid: string;
  tz: string;
}

export const AIRPORTS_CODES = AIRPORTS.map(({ code }) => code);

export const AIRPORTS_LABELS: Record<string, string> = AIRPORTS.reduce(
  (accumulate, { code, name }) => ({ ...accumulate, [code]: `${code} - ${name}` }),
  {}
);

export const getAirportCodes = (airports: AIRPORT_TYPES[]) => airports.map(({ code }) => code);
export const getAirportLabels = (airports: AIRPORT_TYPES[]): Record<string, string> =>
  airports.reduce((accumulate, { code, name }) => ({ ...accumulate, [code]: `${code} - ${name}` }), {});

export const REQUIRED_VALIDATION = {
  value: true,
  message: "This field is required!",
};

export const POSITIVE_NUMBER_VALIDATION = {
  value: 0,
  message: "The minimum value is 0!",
};

export const COORD_VALIDATION = {
  value: COORDINATE_VALIDATION,
  message: "This is not a valid coordination value!",
};
