import { SxProps, Theme } from "@mui/material/styles";
import { Fragment, ReactElement, useCallback, useState, MouseEvent } from "react";
import MuiPopover from "@mui/material/Popover";

export function Popover({
  children,
  trigger,
  sx,
}: {
  children?: (close: () => void) => ReactElement;
  sx?: SxProps<Theme>;
  trigger: (open: (e: MouseEvent<HTMLElement>) => void, isOpen: boolean) => ReactElement;
}) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const open = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl]
  );

  const close = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const isOpen = Boolean(anchorEl);

  return (
    <Fragment>
      {trigger(open, isOpen)}
      <MuiPopover
        open={isOpen}
        anchorEl={anchorEl}
        onClose={close}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        sx={sx}
      >
        {children && children(close)}
      </MuiPopover>
    </Fragment>
  );
}
