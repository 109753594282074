import { CalloutSection } from "@control-tower/aerq-ui-library";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { DEV_PORTAL_LINK } from "constant";
import { ReactNode } from "react";

export function AuthenticationCallout({ children }: { children: ReactNode }) {
  return (
    <Stack spacing={4}>
      <Typography variant="h6" color="textSecondary" data-testid="auth-title">
        Authentication
      </Typography>
      <CalloutSection>
        <Typography fontWeight={500}>
          (text description of the two types of authentications i.e. SSH keys vs password).
          <Link
            href={DEV_PORTAL_LINK}
            target="_blank"
            sx={{
              ml: 1,
              display: "inline-block",
              color: (theme) => `${theme.palette.primary.main} !important`,
            }}
            underline="none"
          >
            Learn more.
          </Link>
        </Typography>
      </CalloutSection>
      {children}
    </Stack>
  );
}
