import { useGetAllAcmInstanceQuery } from "features";
import { ManageInstance } from "./manageInstances";

export function AcmInstances() {
  const { data: instanceList = [], isFetching, refetch } = useGetAllAcmInstanceQuery();
  const onRefreshInstanceList = () => {
    refetch();
  };

  return (
    <ManageInstance
      isAcmInstance={true}
      instanceList={instanceList}
      isFetching={isFetching}
      onRefreshList={onRefreshInstanceList}
    />
  );
}
