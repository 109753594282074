import { InstanceType } from "features";

export const getCreateTime = (createdAt: Date) => {
  // get total seconds between the times
  const date_now = new Date();
  let delta = Math.ceil(Math.abs(new Date(createdAt).getTime() - date_now.getTime()) / 1000);

  // calculate (and subtract) whole days
  const days = Math.floor(delta / 86400);
  delta -= days * 86400;

  // calculate (and subtract) whole hours
  const hours = Math.floor(delta / 3600) % 24;
  delta -= hours * 3600;

  // calculate (and subtract) whole minutes
  const minutes = Math.floor(delta / 60) % 60;
  delta -= minutes * 60;

  // what's left is seconds
  const seconds = Math.floor(delta % 60); // in theory the modulus is not required
  return { days, hours, minutes, seconds };
};

export const getInstanceCreateTime = (createAt: Date) => {
  const { days, hours, minutes, seconds } = getCreateTime(createAt);
  if (days > 0) {
    return `${days} days ago`;
  } else if (hours > 0) {
    return `${hours} hours ago`;
  } else if (minutes > 0) {
    return `${minutes} minutes ago`;
  } else {
    return `${seconds} seconds ago`;
  }
};

export function elapsedTime(date: string | undefined | null | Date): string {
  if (!date) {
    return "N/A";
  }
  const current = new Date(date);
  if (isNaN(current.getTime())) {
    return "N/A";
  }
  const now = new Date();
  now.setMilliseconds(0);
  current.setMilliseconds(0);
  const diff = (now.getTime() - current.getTime()) / 1000;

  if (diff <= 0) {
    return "now";
  }

  const inYears = Math.floor(diff / 60 / 60 / 24 / 30 / 12);

  if (inYears > 0) {
    return `${inYears} ${inYears > 1 ? "years" : "year"} ago`;
  }

  const inMonths = Math.floor(diff / 60 / 60 / 24 / 30);

  if (inMonths > 0) {
    return `${inMonths} ${inMonths > 1 ? "months" : "month"} ago`;
  }

  const inDays = Math.floor(diff / 60 / 60 / 24);

  if (inDays > 0) {
    return `${inDays} ${inDays > 1 ? "days" : "day"} ago`;
  }

  return current.toLocaleTimeString("en-US", { hour: "numeric", hour12: false, minute: "numeric" });
}

export const Sort = {
  Asc: (a: InstanceType, b: InstanceType) => {
    return +new Date(a.createdAt) - +new Date(b.createdAt);
  },
  Desc: (a: InstanceType, b: InstanceType) => {
    return +new Date(b.createdAt) - +new Date(a.createdAt);
  },
};
