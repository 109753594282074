import { styled } from "@mui/material/styles";
import Paper, { PaperProps } from "@mui/material/Paper";
import { Box, BoxProps, MenuItem, MenuItemProps, Tab, TabProps, Popper, PopperProps } from "@mui/material";
import Menu, { MenuProps } from "@mui/material/Menu";
import { autocompleteClasses } from "@mui/material/Autocomplete";

export const ContentHeaderWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  paddingBottom: theme.spacing(2),
}));

export const Card = styled(Paper)<PaperProps>(({ theme }) => ({
  padding: theme.spacing(4),
  borderRadius: theme.spacing(0.5),
  border: `1px solid #E0E0E0`,
  boxShadow: "none",
  minHeight: "180px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
}));

export const CardHeader = styled(Box)<BoxProps>(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  "& .title-section": {
    "& > a": {
      textDecoration: "none",
      fontSize: "12px",
    },
  },
}));

export const CardBody = styled(Box)<BoxProps>(({ theme }) => ({
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
}));
export const CardFooter = styled(Box)<BoxProps>(() => ({
  textAlign: "right",
}));

export const Loader = styled(Box)<BoxProps>(() => ({
  display: "flex",
  minHeight: "300px",
  justifyContent: "center",
  alignItems: "center",
}));

export const FormWrapper = styled(Box)<BoxProps>(() => ({
  maxWidth: "720px",
}));

export const EmptyBox = styled(Box)<BoxProps>(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  fontSize: "2em",
  color: theme.palette.grey[500],
  minHeight: "200px",
}));

export const ErrorContainer = styled(Box)<BoxProps>(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  height: "100vh",
  backgroundColor: theme.palette.grey[200],
}));

export const ErrorContent = styled(Box)<BoxProps>(() => ({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
}));

export const ErrorIcon = styled("span")(({ theme }) => ({
  display: "inline-block",
  padding: theme.spacing(2),
  "& svg": {
    height: "50px",
    width: "50px",
    "& path": {
      stroke: "#ca0029",
    },
  },
}));

export const HomeContainer = styled(Box)<BoxProps>(() => ({
  display: "flex",
  flexDirection: "column",
  height: "100%",
  mb: -4,
  justifyContent: "space-between",
}));

export const PageContainer = styled(Box)<BoxProps>(() => ({}));

export const CustomTab = styled(Tab)<TabProps>(({ theme }) => {
  return {
    fontSize: "20px",
    // color: "#757575",
    flexDirection: "row",
    minHeight: "48px",
    "&.Mui-selected": {
      marginBottom: 0,
      background: `linear-gradient(135.8deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.dark} 98.64%)`,
      backgroundSize: "100% 3px",
      backgroundPosition: "bottom 0 left 0,bottom 5px left 0",
      backgroundRepeat: "no-repeat",
      color: "#000000",
    },
  };
});

export const DeleteMenuItem = styled(MenuItem)<MenuItemProps>(({ theme }) => ({
  color: "#DF3F41",
  "& svg": {
    width: "18px",
    stroke: "#DF3F41",
  },
}));

export const CustomMenu = styled((props: MenuProps) => <Menu elevation={0} {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 0,
    minWidth: 120,
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenuItem-root": {
      paddingTop: theme.spacing(1.5),
      paddingBottom: theme.spacing(1.5),
      "& .MuiSvgIcon-root": {
        marginRight: theme.spacing(1.5),
      },
      "&:hover": {
        color: theme.palette.primary.light,
      },
      "&:active": {
        color: theme.palette.secondary,
        background: `linear-gradient(135.8deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.dark} 98.64%)`,
      },
    },
  },
}));

export const TypeChips = styled(Box)<BoxProps>(({ theme }) => ({
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
  textTransform: "uppercase",
  fontSize: "60%",
  background: `linear-gradient(135.8deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.dark} 98.64%)`,
  padding: theme.spacing(0.5),
  color: theme.palette.grey[100],
  borderRadius: theme.spacing(0.5),
  fontWeight: 600,
  boxShadow:
    "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
}));

export const AutocompleteListbox = styled(Box)<BoxProps>(({ theme }) => ({
  [`&.${autocompleteClasses.option}`]: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2.5),
    paddingRight: theme.spacing(2.5),
  },
}));

export const PopperComponent = styled(Popper)<PopperProps>(() => ({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    maxHeight: "40vh",
    overflow: "auto",
  },
}));
