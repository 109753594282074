import React from "react";
import Box from "@mui/material/Box";
import { CustomButton } from "@control-tower/aerq-ui-library";
import { ContentHeaderWrapper, CustomMenu } from "theme/elements";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import { useTheme } from "@mui/material/styles";
import { Searchbar } from "../inputs";
import { FilterIcon, GridViewIcon, ListViewIcon, SortAscIcon, SortDescIcon } from "../icons";
import { INSTANCE_TYPES } from "constant";
interface PropsType {
  view?: string;
  onViewChange: (view: string) => void;
  onSearch: (value: string) => void;
  searchText: string;
  sortBy: boolean;
  onSortBy: (value: boolean) => void;
  filterBy: string;
  onFilterBy: (value: string) => void;
  isAcmInstance: boolean;
}

export function ContentHeader({
  onViewChange,
  view,
  onSearch,
  searchText,
  sortBy,
  onSortBy,
  filterBy,
  onFilterBy,
  isAcmInstance,
}: Readonly<PropsType>) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const theme = useTheme();
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const onClose = () => {
    setAnchorEl(null);
  };

  return (
    <ContentHeaderWrapper data-testid="page-control-header">
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <CustomButton
          variant="outlined"
          onClick={() => {
            onSortBy(!sortBy);
          }}
          endIcon={sortBy === true ? <SortDescIcon /> : <SortAscIcon />}
        >
          Sort By
        </CustomButton>
        <Searchbar onSearch={onSearch} searchText={searchText} />
      </Box>
      <Box sx={{ display: "flex" }}>
        {isAcmInstance && (
          <>
            <CustomButton
              variant="outlined"
              endIcon={
                <FilterIcon stroke={filterBy !== "all" ? theme.palette.primary.main : theme.palette.text.primary} />
              }
              onClick={handleClick}
            >
              Filter By
            </CustomButton>
            <CustomMenu
              id="filter-menu"
              aria-labelledby="filter-button"
              anchorEl={anchorEl}
              open={open}
              onClose={() => onClose()}
              data-testid="filter-menu"
            >
              <MenuList dense>
                <MenuItem
                  selected={filterBy === INSTANCE_TYPES.ACM}
                  onClick={() => {
                    onClose();
                    onFilterBy(INSTANCE_TYPES.ACM);
                  }}
                  data-testid="filter-by-acm"
                  sx={{ textTransform: "capitalize" }}
                >
                  {INSTANCE_TYPES.ACM}
                </MenuItem>
                <MenuItem
                  selected={filterBy === INSTANCE_TYPES.SURVEY}
                  onClick={() => {
                    onClose();
                    onFilterBy(INSTANCE_TYPES.SURVEY);
                  }}
                  data-testid="filter-by-survey"
                  sx={{ textTransform: "capitalize" }}
                >
                  {INSTANCE_TYPES.SURVEY}
                </MenuItem>
              </MenuList>
            </CustomMenu>
          </>
        )}
        <Box sx={{ pl: 2 }}>
          <CustomButton
            data-testid="grid-view-icon"
            className={view === "grid" ? "active" : ""}
            onClick={() => onViewChange("grid")}
            variant="icon"
            sx={view === "grid" ? { "& path": { stroke: "#424242" } } : { "& path": { stroke: "#BDBDBD" } }}
          >
            <GridViewIcon />
          </CustomButton>
          <CustomButton
            onClick={() => onViewChange("list")}
            variant="icon"
            className={view === "list" ? "active" : ""}
            data-testid="list-view-icon"
            sx={view === "list" ? { "& path": { stroke: "#424242" } } : { "& path": { stroke: "#BDBDBD" } }}
          >
            <ListViewIcon />
          </CustomButton>
        </Box>
      </Box>
    </ContentHeaderWrapper>
  );
}
