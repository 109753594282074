import React from "react";
import { styled } from "@mui/material";

interface PropsType {
  children?: React.ReactNode;
  className?: string;
}

export const HeaderContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  paddingBottom: theme.spacing(4),
  alignItems: "center",
}));

export function SectionHeader(props: Readonly<PropsType>) {
  return (
    <HeaderContainer data-testid="headerSection" className={props.className}>
      {props.children || ""}
    </HeaderContainer>
  );
}
