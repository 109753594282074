import { useForm } from "react-hook-form";
import { SshType } from "features/instances";
import { Box, Typography, FormControl, TextField } from "@mui/material";
import { CustomButton } from "@control-tower/aerq-ui-library";

export function SshForm({ onClose, onSave }: { onClose: () => void; onSave: (data: SshType) => void }) {
  const {
    handleSubmit,
    register,
    formState: { errors, isLoading, isSubmitting },
  } = useForm<SshType>({
    defaultValues: {
      name: "",
      pubKey: "",
    },
  });

  const onHandleSubmit = async (data: SshType) => {
    onSave(data);
    onClose();
  };

  return (
    <Box sx={{ p: 4 }} data-testid="add-ssh-form">
      <Typography variant="h3" fontWeight={600} pb={4}>
        Add New SSH Key
      </Typography>
      <form id="sshForm" onSubmit={handleSubmit(onHandleSubmit)}>
        <FormControl fullWidth sx={{ mb: 2, mt: 1 }} variant="standard">
          <TextField
            {...register("name", { required: true })}
            data-testid="ssh-name"
            label="Ssh Name"
            error={Boolean(errors.name)}
            variant="outlined"
          />
        </FormControl>
        <FormControl fullWidth sx={{ mb: 2 }} variant="standard">
          <TextField
            data-testid="ssh-key"
            label="Ssh Key"
            multiline
            rows={3}
            {...register("pubKey", { required: true })}
            variant="outlined"
            error={Boolean(errors.pubKey)}
          />
        </FormControl>
        <Box sx={{ mt: 2 }}>
          <CustomButton
            form="sshForm"
            disabled={isSubmitting && isLoading}
            sx={{ mr: 2 }}
            onClick={handleSubmit(onHandleSubmit)}
          >
            Add key
          </CustomButton>
          <CustomButton onClick={() => onClose()} variant="outlined">
            Cancel
          </CustomButton>
        </Box>
      </form>
    </Box>
  );
}
