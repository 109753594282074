import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import { ContentHeader } from "components";
import { InstanceCard, InstanceType } from "features";
import { useMemo, useState } from "react";
import { EmptyBox, Loader } from "theme/elements";
import { Sort } from "utils/dateTimes";
import { Paginator } from "@control-tower/aerq-ui-library";
import Box from "@mui/material/Box";

interface PropsType {
  instanceList: InstanceType[];
  onRefreshList: () => void;
  isFetching: boolean;
  isAcmInstance?: boolean;
}

export function ManageInstance({ instanceList, onRefreshList, isFetching, isAcmInstance = false }: PropsType) {
  const [view, setView] = useState<string>("grid");
  const [searchText, setSearchText] = useState<string>("");
  const [sortBy, setSortBy] = useState<boolean>(true);
  const [filterBy, setFilterBy] = useState<string>("all");
  const [pageNumber, setPageNumber] = useState<number>(1);
  const resultPerPage = 10;

  const onViewChange = (view: string) => {
    setView(view);
  };

  const onSearchAction = (value: string) => {
    setSearchText(value);
  };

  const onSortBy = (value: boolean) => {
    setSortBy(value);
  };

  const onFilterBy = (value: string) => {
    setFilterBy(value === filterBy ? "all" : value);
  };

  const onClickPage = (page: number) => {
    setPageNumber(page);
  };

  const getFilteredList: InstanceType[] = useMemo(() => {
    const _instanceList = [...instanceList];
    sortBy === true ? _instanceList.sort(Sort.Desc) : _instanceList.sort(Sort.Asc);
    return _instanceList.slice((pageNumber - 1) * resultPerPage, pageNumber * resultPerPage);
  }, [instanceList, pageNumber, sortBy]);

  const searchItems = getFilteredList.filter((instance: InstanceType) => {
    if (filterBy === "all") {
      return instance.prettyName.toLowerCase().includes(searchText.toLowerCase());
    } else {
      return instance.prettyName.toLowerCase().includes(searchText.toLowerCase()) && instance.type === filterBy;
    }
  });

  return (
    <>
      <ContentHeader
        view={view}
        onViewChange={onViewChange}
        onSearch={onSearchAction}
        searchText={searchText}
        sortBy={sortBy}
        onSortBy={onSortBy}
        filterBy={filterBy}
        onFilterBy={onFilterBy}
        isAcmInstance={isAcmInstance}
      />

      {isFetching ? (
        <Loader data-testid="loader">
          <CircularProgress />
        </Loader>
      ) : (
        <Box>
          {searchItems.length > 0 ? (
            <Grid container rowSpacing={2} columnSpacing={{ xs: 2, sm: 2, md: 6 }} data-testid="instance-list">
              {searchItems.map((instance: InstanceType) => {
                return (
                  <Grid
                    data-testid="instance"
                    item
                    xs={view === "grid" ? 6 : 12}
                    sm={view === "grid" ? 6 : 12}
                    md={view === "grid" ? 4 : 12}
                    key={instance.instanceID}
                    sx={{ "& a": { textDecoration: "none" } }}
                  >
                    <InstanceCard
                      instance={instance}
                      view={view}
                      onRefreshList={onRefreshList}
                      isAcmInstance={isAcmInstance}
                    />
                  </Grid>
                );
              })}
            </Grid>
          ) : (
            <EmptyBox data-testid="empty-instance">Let&rsquo;s Create One!</EmptyBox>
          )}
        </Box>
      )}
      {instanceList.length > resultPerPage && (
        <Box data-testid="pagination-container">
          <Paginator
            baseURL=""
            jumpToSectionName=""
            page={pageNumber}
            resultsNum={instanceList.length}
            resultsPerPage={resultPerPage}
            onClickPage={onClickPage}
            prevLabel="Previous"
            nextLabel="Next"
          />
        </Box>
      )}
    </>
  );
}
