import React, { useState, useEffect } from "react";
import Collapse from "@mui/material/Collapse";

export function ShowUntil({ promise, children }: { promise: Promise<any> | null; children: React.ReactNode }) {
  const [show, setShow] = useState(false);
  useEffect(() => {
    if (!promise) {
      return;
    }
    (async () => {
      setShow(true);
      await promise;
      setShow(false);
    })();
  }, [setShow, promise]);

  return <Collapse in={show}>{children}</Collapse>;
}
