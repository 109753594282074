import { useOutletContext } from "react-router-dom";
import { MutableRefObject, useEffect, useState } from "react";
import { useForm, Control, Controller } from "react-hook-form";
import { nanoid } from "nanoid";
import { Box, FormControl, Stack, TextField, Typography } from "@mui/material";
import { LoadButton, Modal } from "components";
import { DATABASE_PASSWORD_REQUIREMENTS } from "constant";
import { AuthenticationCallout, ServiceCredentialComponent, SshSettingsComponent } from "features/settings";
import { AuthMethod, Settings, useGetSettingsQuery, useUpdateSettingsMutation } from "services/settings.service";
import { CustomButton } from "@control-tower/aerq-ui-library";
import { SshForm } from "features/settings/components/SshForm";

export function Access() {
  const { data: settings, refetch: refetchSettings } = useGetSettingsQuery();
  const [updateSettings] = useUpdateSettingsMutation();

  const {
    register,
    formState: { errors },
    setFocus,
    watch,
    handleSubmit,
    setValue,
    control,
    reset,
  } = useForm<Settings>({
    defaultValues: settings || {
      svcUsername: "",
      svcPassword: "",
      rootPassword: "",
      preferredAuthMethod: AuthMethod.SSHKEY,
      sshKeys: [],
    },
  });

  const ref: MutableRefObject<HTMLElement> = useOutletContext();

  useEffect(() => {
    if (!ref || !ref.current) {
      return;
    }
    const handler: () => void = handleSubmit(async (formData) => {
      await updateSettings({ ...settings, ...formData });
      refetchSettings();
    });
    ref.current.addEventListener("click", handler);
    return () => {
      if (!ref || !ref.current) {
        return;
      }
      ref.current.removeEventListener("click", handler);
    };
  }, [handleSubmit, ref, updateSettings]);

  useEffect(() => {
    reset(settings);
  }, [settings, reset]);

  const method = watch("preferredAuthMethod", AuthMethod.SSHKEY);
  const authMethodIsSSH = method === AuthMethod.SSHKEY;
  const authMethodIsPassword = method === AuthMethod.PASSWORD;

  return (
    <Box sx={{ position: "relative", mb: 4 }} data-testid="container">
      <AuthenticationCallout>
        <Controller
          control={control}
          name="sshKeys"
          render={({ field }) => (
            <Box data-testid="ssh-settings">
              <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", pb: 3 }}>
                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography variant="h6" sx={{ fontSize: "16px", fontWeight: "600" }}>
                    SSH key
                  </Typography>
                  {authMethodIsSSH ? (
                    <Typography color="primary">(Default)</Typography>
                  ) : (
                    <CustomButton
                      data-testid="ssh-default-btn"
                      variant="outlined"
                      onClick={() => setValue("preferredAuthMethod", AuthMethod.SSHKEY)}
                    >
                      Set as default
                    </CustomButton>
                  )}
                </Stack>
                <Modal
                  dialogProps={({ onClose }) => ({
                    children: <SshForm onClose={onClose} onSave={(data) => field.onChange([data, ...field.value])} />,
                    fullWidth: true,
                    onClose,
                    scroll: "paper",
                  })}
                >
                  {({ onOpen }) => (
                    <CustomButton variant="outlined" onClick={onOpen}>
                      Add new key
                    </CustomButton>
                  )}
                </Modal>
              </Box>
              <SshSettingsComponent sshList={field.value} onUpdateSsh={field.onChange} />
            </Box>
          )}
        />
        <Box data-testid="pw-settings">
          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", pb: 3 }}>
            <Stack direction="row" spacing={1} alignItems="center" minHeight={48}>
              <Typography variant="h6" sx={{ fontSize: "16px", fontWeight: "600" }}>
                Password
              </Typography>
              {authMethodIsPassword ? (
                <Typography color="primary">(Default)</Typography>
              ) : (
                <CustomButton
                  data-testid="password-default-btn"
                  variant="outlined"
                  onClick={() => setValue("preferredAuthMethod", AuthMethod.PASSWORD)}
                >
                  Set as default
                </CustomButton>
              )}
            </Stack>
          </Box>
          <FormControl fullWidth sx={{ mb: 2, mt: 1 }} variant="standard">
            <TextField
              {...register("rootPassword", {
                minLength: {
                  message: "Password must be at least 10 character length!",
                  value: 10,
                },
                pattern: {
                  message: "Password must contains at least one number, one lowercase and one uppercase letter!",
                  value: DATABASE_PASSWORD_REQUIREMENTS,
                },
              })}
              data-testid="rootPassword"
              label="Instance password"
              error={Boolean(errors?.rootPassword)}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                endAdornment: (
                  <LoadButton
                    data-testid="generate-root-password"
                    action={async () => {
                      const password = await new Promise<string>((resolve) => {
                        let passwordTry = nanoid(12);
                        while (!DATABASE_PASSWORD_REQUIREMENTS.test(passwordTry)) {
                          passwordTry = nanoid(12);
                        }
                        resolve(passwordTry);
                      });
                      setFocus("rootPassword");
                      setValue("rootPassword", password, {
                        shouldDirty: true,
                        shouldTouch: true,
                        shouldValidate: true,
                      });
                    }}
                    buttonProps={{
                      variant: "outlined",
                    }}
                  >
                    Generate
                  </LoadButton>
                ),
              }}
              variant="outlined"
              helperText={errors?.rootPassword?.message}
            />
          </FormControl>
        </Box>
      </AuthenticationCallout>
      <ServiceCredentialComponent>
        <FormControl fullWidth sx={{ mb: 2, mt: 1 }} variant="standard">
          <TextField
            {...register("svcUsername", {
              minLength: {
                value: 6,
                message: "Username must be at least 6 character length!",
              },
            })}
            data-testid="username"
            label="Username"
            error={Boolean(errors?.svcUsername)}
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            helperText={errors?.svcUsername?.message}
          />
        </FormControl>
        <FormControl fullWidth sx={{ mb: 2, mt: 1 }} variant="standard">
          <TextField
            {...register("svcPassword", {
              minLength: {
                message: "Password must be at least 10 character length!",
                value: 10,
              },
              pattern: {
                message: "Password must contains at least one number, one lowercase and one uppercase letter!",
                value: DATABASE_PASSWORD_REQUIREMENTS,
              },
            })}
            data-testid="password"
            label="Password"
            error={Boolean(errors?.svcPassword)}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              endAdornment: (
                <LoadButton
                  data-testid="generate-svc-password"
                  action={async () => {
                    const password = await new Promise<string>((resolve) => {
                      let passwordTry = nanoid(12);
                      while (!DATABASE_PASSWORD_REQUIREMENTS.test(passwordTry)) {
                        passwordTry = nanoid(12);
                      }
                      resolve(passwordTry);
                    });
                    setFocus("svcPassword");
                    setValue("svcPassword", password, {
                      shouldDirty: true,
                      shouldTouch: true,
                      shouldValidate: true,
                    });
                  }}
                  buttonProps={{
                    variant: "outlined",
                  }}
                >
                  Generate
                </LoadButton>
              ),
            }}
            variant="outlined"
            helperText={errors?.svcPassword?.message}
          />
        </FormControl>
      </ServiceCredentialComponent>
    </Box>
  );
}
