import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { Loadingindicator } from "@control-tower/aerq-ui-library";
import { store } from "store";
import GroundThemeProvider from "theme";
import App from "App";

if (process.env.NODE_ENV === "development" && String(process.env.REACT_APP_MOCK_SERVER_ENABLED) === "true") {
  import("utils/testing/mockServer/browser").then(({ worker }) => {
    worker.start({ onUnhandledRequest: "bypass" });
  });
}

const rootElement = document.getElementById("root");
const root = ReactDOM.createRoot(rootElement as HTMLElement);

root.render(
  <Suspense fallback={<Loadingindicator />}>
    <GroundThemeProvider>
      <Provider store={store}>
        <Router>
          <App />
        </Router>
      </Provider>
    </GroundThemeProvider>
  </Suspense>
);
