import { ReactNode, useCallback, useState } from "react";

export function HideText({
  hideByDefault = true,
  text,
  children,
}: {
  hideByDefault?: boolean;
  text: string;
  children: (text: string, hide: boolean, toggle: () => void) => ReactNode;
}) {
  const [hide, setHide] = useState(hideByDefault);

  const toggle = useCallback(() => {
    setHide((old) => !old);
  }, [setHide]);

  return <>{children(hide ? [...text].map(() => "*").join("") : text, hide, toggle)}</>;
}
