import { useDispatch } from "react-redux";
import type { PreloadedState } from "@reduxjs/toolkit";
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import userSlice from "services/userSlice.service";
import settingsSlice from "services/settingsSlice.service";
import errorSlice from "services/errorHandler.service";
import { api } from "lib";

const rootReducer = combineReducers({
  [api.reducerPath]: api.reducer,
  [userSlice.name]: userSlice.reducer,
  [errorSlice.name]: errorSlice.reducer,
  [settingsSlice.name]: settingsSlice.reducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export const CreateStore = (preloadedState?: PreloadedState<RootState>) => {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(api.middleware),
    preloadedState,
  });
};

export const store = CreateStore();

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;

export type AppStore = ReturnType<typeof CreateStore>;
