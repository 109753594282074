import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { CreateCredentialType, SshType } from "features";
import { RootState } from "store";

interface IAccessSettings extends CreateCredentialType {
  sshList: SshType[];
}

interface IGeneralSettings {}

export interface ISettingsStates {
  accessSettings: IAccessSettings;
  generalSettings: IGeneralSettings;
}

export const initialState: ISettingsStates = {
  accessSettings: {
    sshList: [],
    svcUsername: "",
    svcPassword: "",
  },
  generalSettings: {},
};

export const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    addSsh: (state, action: PayloadAction<any>) => {
      state.accessSettings = {
        ...state.accessSettings,
        sshList: [action.payload, ...state.accessSettings.sshList],
      };
    },
    updateDBCredential: (state, action: PayloadAction<any>) => {
      state.accessSettings = {
        ...state.accessSettings,
        svcUsername: action.payload.svcUsername,
        svcPassword: action.payload.svcPassword,
      };
    },
  },
});

export const { addSsh, updateDBCredential } = settingsSlice.actions;
export const getSettings = (state: RootState) => state.settings;
export const getAccessSettings = (state: RootState) => state.settings?.accessSettings;

export default settingsSlice;
