import { api } from "lib";
import { SeatInfo } from "../types";

export const seatIdApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getSeat: builder.query<SeatInfo, string>({
      query: (url) => ({ url: url + "/api/config/seat-config" }),
      providesTags: ["SeatId"],
    }),
    updateSeat: builder.mutation<string, { url: string; body: Partial<SeatInfo> }>({
      query: (args) => ({
        url: args.url + "/mock",
        method: "PUT",
        body: args.body,
        responseHandler: (response: { text: () => any }) => response.text(),
      }),
      async onQueryStarted({ body, url }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          seatIdApi.util.updateQueryData("getSeat", url, (draft) => {
            Object.assign(draft, body);
          })
        );
        queryFulfilled.catch(patchResult.undo);
      },
    }),
  }),
});

export const { useGetSeatQuery, useUpdateSeatMutation } = seatIdApi;
