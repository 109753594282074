import React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { CustomButton } from "@control-tower/aerq-ui-library";
import { PlusIcon, LinkTab } from "components";
import { Router } from "constant/router";
import { useGetAllInstanceQuery, useGetAllAcmInstanceQuery } from "features";
import { HomeContainer } from "theme/elements";
import roles from "constant/roles";
import { usePermissions } from "common";

export function Home() {
  const navigate = useNavigate();

  const [canCreateAcmInstance] = usePermissions(roles.createACMInstance);
  const [canViewAcmInstance] = usePermissions(roles.vtrDeveloperAdmin);

  const { data: instanceList = [] } = useGetAllInstanceQuery();
  const { data: acmInstanceList = [] } = useGetAllAcmInstanceQuery(undefined, {
    skip: !(canCreateAcmInstance || canViewAcmInstance),
  });

  const onNewInstance = () => {
    navigate(Router.instance().create());
  };

  const onAcmNewInstance = () => {
    navigate(Router.acmInstance().create());
  };

  return (
    <HomeContainer data-testid="homepage">
      <Box sx={{ pb: 2 }}>
        <Typography paragraph variant="h1" data-testid="page-title">
          Overview
        </Typography>
        <Stack direction="row" justifyContent="space-between" spacing={2} mb={2}>
          <Stack direction="row" spacing={2}>
            <LinkTab
              to={Router.overview().instances()}
              label={`App Instances (${instanceList.length})`}
              data-testid="app-instance"
            />
            {(canCreateAcmInstance || canViewAcmInstance) && (
              <LinkTab
                to={Router.overview().acmInstances()}
                label={`Virtual Aircrafts (${acmInstanceList.length})`}
                data-testid="app-aircraft"
              />
            )}
          </Stack>
          <Box sx={{ display: "flex" }}>
            <CustomButton
              data-testid="create-app-instance"
              variant="outlined"
              sx={{ mr: 1 }}
              onClick={onNewInstance}
              startIcon={<PlusIcon />}
            >
              Create App Instance
            </CustomButton>
            {canCreateAcmInstance && (
              <CustomButton
                variant="outlined"
                data-testid="create-acm-instance"
                onClick={onAcmNewInstance}
                startIcon={<PlusIcon />}
              >
                Create ACM Instance
              </CustomButton>
            )}
          </Box>
        </Stack>
        <Outlet />
      </Box>
    </HomeContainer>
  );
}
