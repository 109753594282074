import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography, FormControl, TextField, Stack, FormControlLabel, Radio } from "@mui/material";
import { ContentContainerTemplate, PageContent, PageTemplate, CustomButton } from "@control-tower/aerq-ui-library";
import { SectionHeader, LoadButton, Modal } from "components";
import { useForm } from "react-hook-form";
import { nanoid } from "nanoid";
import {
  AuthenticationCallout,
  CreateInstanceType,
  ServiceCredentialComponent,
  useCreateInstanceMutation,
} from "features";
import { DATABASE_PASSWORD_REQUIREMENTS } from "constant";
import { Router } from "constant/router";
import { useSelector } from "react-redux";
import { getUser } from "services/userSlice.service";
import {
  AuthMethod,
  useGetServiceCatalogQuery,
  useGetSettingsQuery,
  useUpdateSettingsMutation,
} from "services/settings.service";
import { AutocompleteField } from "lib/@fapi/AutocompleteField";
import { SshForm } from "features/settings/components/SshForm";
import { AutocompleteListbox } from "theme/elements";

export function CreateInstance() {
  const { preferred_username } = useSelector(getUser);
  const { data: services = [] } = useGetServiceCatalogQuery();
  const { data: settings, refetch } = useGetSettingsQuery();
  const [updateSettings] = useUpdateSettingsMutation();
  const [authMethod, setAuthMethod] = useState(AuthMethod.SSHKEY);

  const dbUserName = (userName: string | undefined) => {
    if (!userName) return "";
    return userName.split("@")[0];
  };

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors, isLoading, isSubmitting },
    setValue,
    control,
    setFocus,
  } = useForm<CreateInstanceType>({
    defaultValues: {
      name: "",
      sshPub: "",
      details: "",
      database: {
        username: "",
        password: "",
      },
      rootPassword: settings?.rootPassword || "",
    },
    mode: "all",
  });

  useEffect(() => {
    reset({
      name: "",
      sshPub: "",
      details: "",
      database: {
        username: settings?.svcUsername || dbUserName(preferred_username) || "",
        password: settings?.svcPassword || nanoid(12),
      },
      rootPassword: settings?.rootPassword || "",
    });
    setAuthMethod(settings?.preferredAuthMethod || AuthMethod.SSHKEY);
  }, [settings, reset, preferred_username, setAuthMethod]);

  const navigate = useNavigate();
  const [createInstance] = useCreateInstanceMutation();

  const authMethodIsPassword = authMethod === AuthMethod.PASSWORD;
  const authMethodIsSSH = authMethod === AuthMethod.SSHKEY;

  const keys: Record<string, string> = settings?.sshKeys
    ? settings?.sshKeys.reduce((accumulate, key) => ({ ...accumulate, [key.name]: key.pubKey }), {})
    : {};

  return (
    <PageTemplate>
      <PageContent backgroundHeight={600}>
        <ContentContainerTemplate className={"main-container"} data-testid="instance-create">
          <form
            id="instanceForm"
            onSubmit={handleSubmit(async ({ rootPassword, sshPub, ...data }) => {
              await createInstance({
                ...data,
                services: services.map((s) => ({
                  name: s.serviceName,
                  version: [...s.versions].pop() || "N/A",
                })),
                sshPub: authMethodIsSSH ? keys[sshPub] : "",
                rootPassword: authMethodIsPassword ? rootPassword : "",
              });
              navigate(Router.overview().instances());
            })}
          >
            <SectionHeader>
              <Typography variant="h3" fontWeight={"bold"}>
                Add New Instance
              </Typography>
              <Box>
                <CustomButton form="instanceForm" disabled={isSubmitting && isLoading} sx={{ mr: 2 }} type="submit">
                  Save
                </CustomButton>
                <CustomButton onClick={() => navigate(Router.overview().instances())} variant="outlined">
                  Cancel
                </CustomButton>
              </Box>
            </SectionHeader>
            <Stack maxWidth={720} spacing={8}>
              <Stack spacing={4}>
                <Typography variant="h6" color="textSecondary">
                  General
                </Typography>
                <Stack spacing={3}>
                  <FormControl fullWidth sx={{ mb: 2, mt: 1 }} variant="standard">
                    <TextField
                      {...register("name", { required: true })}
                      data-testid="instance-name"
                      label="Name of instance"
                      error={Boolean(errors.name)}
                      required
                      variant="outlined"
                    />
                  </FormControl>
                  <FormControl fullWidth sx={{ mb: 2 }} variant="standard">
                    <TextField
                      data-testid="instance-details"
                      label="Description (optional)"
                      {...register("details")}
                      error={Boolean(errors.details)}
                      variant="outlined"
                    />
                  </FormControl>
                </Stack>
              </Stack>
              <AuthenticationCallout>
                <Stack direction="row" justifyContent="space-between">
                  <FormControlLabel
                    onClick={() => setAuthMethod(AuthMethod.SSHKEY)}
                    checked={authMethodIsSSH}
                    control={<Radio data-testid="ssh-radio" />}
                    label="SSH Key"
                  />
                  <Modal
                    dialogProps={({ onClose }) => ({
                      children: (
                        <SshForm
                          onClose={onClose}
                          onSave={async (data) => {
                            await updateSettings({
                              ...settings,
                              sshKeys: settings ? [data, ...settings.sshKeys] : [data],
                            });
                            await refetch();
                          }}
                        />
                      ),
                      fullWidth: true,
                      onClose,
                      scroll: "paper",
                    })}
                  >
                    {({ onOpen }) => (
                      <CustomButton variant="outlined" onClick={onOpen}>
                        Add new key
                      </CustomButton>
                    )}
                  </Modal>
                </Stack>
                <AutocompleteField
                  control={control}
                  name="sshPub"
                  rules={{
                    required: authMethodIsSSH,
                  }}
                  disabled={!authMethodIsSSH}
                  options={Object.keys(keys)}
                  renderOption={(props, option) => (
                    <AutocompleteListbox key={option} component="li" {...props}>
                      {option}
                    </AutocompleteListbox>
                  )}
                  renderInput={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      fullWidth
                      disabled={!authMethodIsSSH}
                      label="Select SSH key"
                      data-testid="sshkeyfield"
                      required={authMethodIsSSH}
                      error={Boolean(fieldState.error?.message)}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
                <Box>
                  <FormControlLabel
                    onClick={() => setAuthMethod(AuthMethod.PASSWORD)}
                    checked={authMethodIsPassword}
                    control={<Radio data-testid="set-password-radio" />}
                    label="Password"
                  />
                </Box>
                <FormControl fullWidth sx={{ mb: 2, mt: 1 }} variant="standard">
                  <TextField
                    {...register("rootPassword", {
                      required: authMethodIsPassword,
                      minLength: {
                        message: "Password must be at least 10 character length!",
                        value: 10,
                      },
                      pattern: {
                        message: "Password must contains at least one number, one lowercase and one uppercase letter!",
                        value: DATABASE_PASSWORD_REQUIREMENTS,
                      },
                    })}
                    disabled={!authMethodIsPassword}
                    data-testid="rootPassword"
                    label="Instance password"
                    required={authMethodIsPassword}
                    error={Boolean(errors?.rootPassword)}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      endAdornment: (
                        <LoadButton
                          data-testid="password-generate-btn"
                          action={async () => {
                            const password = await new Promise<string>((resolve) => {
                              let passwordTry = nanoid(12);
                              while (!DATABASE_PASSWORD_REQUIREMENTS.test(passwordTry)) {
                                passwordTry = nanoid(12);
                              }
                              resolve(passwordTry);
                            });
                            setFocus("rootPassword");
                            setValue("rootPassword", password, {
                              shouldDirty: true,
                              shouldTouch: true,
                              shouldValidate: true,
                            });
                          }}
                          buttonProps={{
                            variant: "outlined",
                          }}
                        >
                          Generate
                        </LoadButton>
                      ),
                    }}
                    variant="outlined"
                    helperText={errors?.rootPassword?.message}
                  />
                </FormControl>
              </AuthenticationCallout>
              <ServiceCredentialComponent>
                <FormControl fullWidth sx={{ mb: 2, mt: 1 }} variant="standard">
                  <TextField
                    {...register("database.username", {
                      required: true,
                      minLength: {
                        value: 6,
                        message: "Username must be at least 6 character length!",
                      },
                    })}
                    data-testid="username"
                    label="Username"
                    error={Boolean(errors?.database?.username)}
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                    helperText={errors?.database?.username?.message}
                    required
                  />
                </FormControl>
                <FormControl fullWidth sx={{ mb: 2, mt: 1 }} variant="standard">
                  <TextField
                    {...register("database.password", {
                      required: true,
                      minLength: {
                        message: "Password must be at least 10 character length!",
                        value: 10,
                      },
                      pattern: {
                        message: "Password must contains at least one number, one lowercase and one uppercase letter!",
                        value: DATABASE_PASSWORD_REQUIREMENTS,
                      },
                    })}
                    data-testid="password"
                    label="Password"
                    error={Boolean(errors?.database?.password)}
                    required
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      endAdornment: (
                        <LoadButton
                          data-testid="root-password-btn"
                          action={async () => {
                            const password = await new Promise<string>((resolve) => {
                              let passwordTry = nanoid(12);
                              while (!DATABASE_PASSWORD_REQUIREMENTS.test(passwordTry)) {
                                passwordTry = nanoid(12);
                              }
                              resolve(passwordTry);
                            });
                            setFocus("database.password");
                            setValue("database.password", password, {
                              shouldDirty: true,
                              shouldTouch: true,
                              shouldValidate: true,
                            });
                          }}
                          buttonProps={{
                            variant: "outlined",
                          }}
                        >
                          Generate
                        </LoadButton>
                      ),
                    }}
                    variant="outlined"
                    helperText={errors?.database?.password?.message}
                  />
                </FormControl>
              </ServiceCredentialComponent>
            </Stack>
          </form>
        </ContentContainerTemplate>
      </PageContent>
    </PageTemplate>
  );
}
