import Box from "@mui/material/Box";
import { ReactElement } from "react";

export function Sidebar({ children }: { children: ReactElement }) {
  return (
    <Box sx={{ borderColor: "divider", borderRightWidth: 1, borderRightStyle: "solid" }} flex={1}>
      {children}
    </Box>
  );
}
