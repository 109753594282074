import { useNavigate } from "react-router-dom";
import { Router } from "constant/router";
import { Box, Typography, FormControl, TextField, Stack } from "@mui/material";
import { ContentContainerTemplate, PageContent, PageTemplate, CustomButton } from "@control-tower/aerq-ui-library";
import { useForm } from "react-hook-form";
import { SectionHeader } from "components";
import { AutocompleteField } from "lib/@fapi/AutocompleteField";
import { CreateAcmInstanceType, useCreateAcmInstanceMutation, useGetAcmBranchesQuery } from "features";
import { AutocompleteListbox } from "theme/elements";

export function CreateAcmInstance() {
  const navigate = useNavigate();

  const { data: branches = [] } = useGetAcmBranchesQuery();

  const {
    handleSubmit,
    register,
    formState: { errors, isLoading, isSubmitting },
    control,
  } = useForm<CreateAcmInstanceType>({
    defaultValues: {
      prettyName: "",
      targetBranch: null,
    },
    mode: "all",
  });

  const [createAcmInstance] = useCreateAcmInstanceMutation();

  const keys: Record<string, string> = branches
    ? branches.reduce((accumulate, item) => ({ ...accumulate, [item.branchName]: item.branchName }), {})
    : {};

  return (
    <PageTemplate>
      <PageContent backgroundHeight={600}>
        <ContentContainerTemplate className={"main-container"} data-testid="acm-instance-create">
          <form
            id="acmInstanceForm"
            onSubmit={handleSubmit(async ({ ...data }) => {
              await createAcmInstance({
                ...data,
              });
              navigate(Router.overview().acmInstances());
            })}
          >
            <SectionHeader>
              <Typography variant="h3" fontWeight={"bold"}>
                Add New Instance
              </Typography>
              <Box>
                <CustomButton form="acmInstanceForm" disabled={isSubmitting && isLoading} sx={{ mr: 2 }} type="submit">
                  Save
                </CustomButton>
                <CustomButton onClick={() => navigate(Router.overview().acmInstances())} variant="outlined">
                  Cancel
                </CustomButton>
              </Box>
            </SectionHeader>
            <Stack maxWidth={720} spacing={8}>
              <Stack spacing={4}>
                <Stack spacing={3}>
                  <FormControl fullWidth sx={{ mb: 2, mt: 1 }} variant="standard">
                    <TextField
                      {...register("prettyName", { required: true })}
                      data-testid="instance-name"
                      label="Name of instance"
                      error={Boolean(errors.prettyName)}
                      required
                      variant="outlined"
                    />
                  </FormControl>
                </Stack>
                <Stack spacing={3}>
                  <AutocompleteField
                    control={control}
                    name="targetBranch"
                    rules={{
                      required: true,
                    }}
                    options={Object.keys(keys)}
                    renderOption={(props, option) => (
                      <AutocompleteListbox key={option} component="li" {...props}>
                        {keys[option]}
                      </AutocompleteListbox>
                    )}
                    renderInput={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        fullWidth
                        label="Select branch name"
                        data-testid="targetBranch"
                        required={true}
                        error={Boolean(fieldState.error?.message)}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                </Stack>
              </Stack>
            </Stack>
          </form>
        </ContentContainerTemplate>
      </PageContent>
    </PageTemplate>
  );
}
