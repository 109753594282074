import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store";

export interface IErrorStates {
  errorCode?: string | null;
  message: string[];
}

const initialState: IErrorStates = {
  message: [],
  errorCode: null,
};

export const errorSlice = createSlice({
  name: "error",
  initialState,
  reducers: {
    updateError: (state, action: PayloadAction<any>) => {
      return action.payload;
    },
  },
});

export const { updateError } = errorSlice.actions;
export const getError = (state: RootState) => state.error;
export const getErrorMessage = (state: RootState) => state.error?.message;
export default errorSlice;
