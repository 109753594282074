import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import { Tree, TreeItem } from "components";
import { ServiceListHeader, AvailableService } from "features/instances";
import { useMemo, useState } from "react";
import { capitalize } from "utils/string";

export function ServiceListSettings({ services }: { services: AvailableService[] }) {
  const [selected, setSelected] = useState<Map<string, string>>(() => {
    const versions = new Map();

    services.forEach((service) => {
      versions.set(service.name, [...service.versions].pop());
    });

    return versions;
  });

  const items: TreeItem[] = useMemo(
    () =>
      services.reduce<TreeItem[]>((accumulate, service) => {
        const index = accumulate.findIndex((item) => item.id === service.type);
        if (index === -1) {
          return [
            ...accumulate,
            {
              id: service.type,
              label: capitalize(service.type),
              children: [
                {
                  id: service.name,
                  label: service.serviceName,
                  children: service.versions.map((version) => ({ id: version, label: version })),
                },
              ],
            },
          ];
        } else {
          accumulate[index].children?.push({
            id: service.name,
            label: service.serviceName,
            children: service.versions.map((version) => ({ id: version, label: version })),
          });
          return accumulate;
        }
      }, []),
    [services]
  );

  return (
    <ServiceListHeader>
      <Tree
        items={items}
        renderItem={(parent, item) => {
          if (parent && parent.children && parent.children.length > 1) {
            return (
              <FormControlLabel
                label={item.label}
                control={
                  <Radio
                    checked={selected.get(parent.id) === item.id}
                    onChange={() => {
                      setSelected((old) => {
                        const update = new Map(old.entries());
                        if (old.get(parent.id) === item.id) {
                          update.delete(parent.id);
                        } else {
                          update.set(parent.id, item.id);
                        }
                        return update;
                      });
                    }}
                  />
                }
              />
            );
          }
          return item.label;
        }}
      />
    </ServiceListHeader>
  );
}
