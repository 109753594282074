import { CalloutSection } from "@control-tower/aerq-ui-library";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { DEV_PORTAL_LINK } from "constant";
import { ReactNode } from "react";

export function ServiceListHeader({ children }: { children: ReactNode }) {
  return (
    <Stack spacing={3}>
      <Typography variant="h6" color="textSecondary">
        Available services
      </Typography>
      <CalloutSection>
        <Typography fontWeight={500}>
          (text description of what services mean in VTR & which versions should be used for a specific aircraft,
          combined with a text link to Devs Portal).
          <Link
            href={DEV_PORTAL_LINK}
            target="_blank"
            sx={{
              display: "inline-block",
              ml: 1,
              color: (theme) => `${theme.palette.primary.main} !important`,
            }}
            underline="none"
          >
            Learn more.
          </Link>
        </Typography>
      </CalloutSection>
      {children}
    </Stack>
  );
}
