import { NavigationSidebar, NavigationSidebarItem, NavigationSidebarSection } from "@control-tower/aerq-ui-library";
import { useLocation, useParams } from "react-router-dom";
import { useNavigate } from "utils/useNavigate";
import { LogsIcon, OverviewIcon, ServicesIcon } from "components";
import { Router } from "constant/router";
import { InstanceSelector } from "./InstanceSelector";

export function InstanceNavigation({ isAcmInstance = false }: { isAcmInstance?: boolean }) {
  const location = useLocation();
  const { instanceId } = useParams();
  const navigateTo = useNavigate();
  return (
    <div>
      <NavigationSidebar location={location} navigateTo={navigateTo}>
        <NavigationSidebarSection>
          <InstanceSelector />
          <NavigationSidebarItem
            label="Services"
            icon={<ServicesIcon />}
            link={{
              path: isAcmInstance ? Router.acmInstance().services(instanceId) : Router.instance().services(instanceId),
              external: false,
            }}
          />
          <NavigationSidebarItem
            label="Details"
            icon={<OverviewIcon />}
            link={{
              path: isAcmInstance ? Router.acmInstance().details(instanceId) : Router.instance().details(instanceId),
              external: false,
            }}
          />
          <NavigationSidebarItem
            label="Logs"
            icon={<LogsIcon />}
            link={{
              path: isAcmInstance ? Router.acmInstance().logs(instanceId) : Router.instance().logs(instanceId),
              external: false,
            }}
          />
        </NavigationSidebarSection>
      </NavigationSidebar>
    </div>
  );
}
