import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

export function Seat({ letter, selected, onClick }: { letter: string; selected?: boolean; onClick?: () => void }) {
  return (
    <Box
      data-testid="seat"
      aria-selected={selected}
      onClick={onClick}
      width={70}
      height={70}
      display="flex"
      alignItems="center"
      justifyContent="center"
      position="relative"
      padding="12px"
      sx={{
        cursor: "pointer",
      }}
    >
      <Typography mb={1} variant="h4" fontWeight={500} fontSize="1.8rem" color={selected ? "textPrimary" : "#AAA"}>
        {letter}
      </Typography>
      <Box
        left={0}
        width={12}
        height={70}
        borderRadius={1}
        bgcolor={selected ? "primary.main" : "#D4D4D4"}
        position="absolute"
      />
      <Box
        right={0}
        width={12}
        height={70}
        borderRadius={1}
        bgcolor={selected ? "primary.main" : "#D4D4D4"}
        position="absolute"
      />
      <Box
        bottom={0}
        width={70}
        height={12}
        borderRadius={1}
        bgcolor={selected ? "primary.main" : "#D4D4D4"}
        position="absolute"
      />
    </Box>
  );
}
