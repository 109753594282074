import React, { ReactNode } from "react";
import Autocomplete, {
  AutocompleteRenderInputParams,
  AutocompleteRenderOptionState,
  AutocompleteOwnerState,
} from "@mui/material/Autocomplete";
import { UseAutocompleteProps } from "@mui/material/useAutocomplete";
import {
  Control,
  Controller,
  ControllerFieldState,
  FieldValues,
  Path,
  PathValue,
  RegisterOptions,
} from "react-hook-form";
import { PopperComponent } from "theme/elements";

export function AutocompleteField<T extends FieldValues>({
  control,
  name,
  options,
  rules,
  validationMessage,
  disabled,
  getOptionLabel,
  renderInput,
  renderOption,
  isOptionEqualToValue,
}: {
  control: Control<T>;
  name: Path<T>;
  disabled?: boolean | undefined;
  options: string[];
  rules?: Omit<RegisterOptions<T, Path<T>>, "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled"> | undefined;
  validationMessage?: string | ((value: PathValue<T, Path<T>>) => string);
  getOptionLabel?: UseAutocompleteProps<string, boolean | undefined, false, false>["getOptionLabel"];
  renderInput: (params: { field: AutocompleteRenderInputParams; fieldState: ControllerFieldState }) => ReactNode;
  renderOption?:
    | ((
        props: React.HTMLAttributes<HTMLElement>,
        option: string,
        state: AutocompleteRenderOptionState,
        ownerState: AutocompleteOwnerState<string, boolean | undefined, false, false, "div">
      ) => React.ReactNode)
    | undefined;
  isOptionEqualToValue?: (option: string, value: string) => boolean;
}) {
  return (
    <Controller
      control={control}
      name={name}
      rules={{
        ...rules,
        validate: (value) => {
          if (!validationMessage) {
            return;
          }
          if (options.includes(value)) {
            return;
          }
          if (typeof validationMessage === "function") {
            return validationMessage(value);
          }
          return validationMessage;
        },
      }}
      render={({ field: { onChange, ...rest }, fieldState }) => (
        <Autocomplete
          {...rest}
          disabled={disabled}
          options={options}
          isOptionEqualToValue={isOptionEqualToValue}
          PopperComponent={PopperComponent}
          renderOption={renderOption}
          data-testid="autoCompleteField"
          getOptionLabel={getOptionLabel}
          onChange={(_, data) => onChange(data)}
          renderInput={(params) => renderInput({ field: params, fieldState })}
        />
      )}
    />
  );
}
