import { NavigationSidebar, NavigationSidebarItem, NavigationSidebarSection } from "@control-tower/aerq-ui-library";
import { AnalyticsIcon, InstanceIcon, SettingsIcon } from "components";
import { Router } from "constant/router";
import { InstanceSelector } from "features/instances";
import { useLocation } from "react-router-dom";
import { useNavigate } from "utils/useNavigate";

export function OverviewNavigation() {
  const location = useLocation();
  const navigateTo = useNavigate();
  return (
    <div>
      <NavigationSidebar location={location} navigateTo={navigateTo}>
        <NavigationSidebarSection>
          <InstanceSelector />
          <NavigationSidebarItem
            label="Overview"
            icon={<InstanceIcon />}
            link={{
              path: Router.overview().instances(),
              external: false,
            }}
          />
          <NavigationSidebarItem
            label="Settings"
            icon={<SettingsIcon />}
            link={{
              path: Router.settings().index(),
              external: false,
            }}
          />
          <NavigationSidebarItem
            label="Analytics"
            icon={<AnalyticsIcon />}
            link={{
              path: Router.analytics(),
              external: false,
            }}
          />
        </NavigationSidebarSection>
      </NavigationSidebar>
    </div>
  );
}
