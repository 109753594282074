import endpoints from "constant/endpoints";
import { api } from "lib";
import { CreateInstanceType, CreateAcmInstanceType, InstanceType, AcmRepoBranchType } from "../types";
// import { updateServices } from "./utils";

export type InstancesResponse = InstanceType[];
export type AcmBranchResponse = AcmRepoBranchType[];

export const instancesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAllInstance: builder.query<InstancesResponse, void>({
      query: () => ({
        url: endpoints.instance.list,
      }),
      providesTags: (result = []) => {
        return [
          ...result.map(({ instanceID }) => ({ type: "Instances", id: instanceID } as const)),
          { type: "Instances" as const, id: "LIST" },
        ];
      },
    }),
    getInstance: builder.query<InstanceType, string | undefined>({
      query: (id) => ({
        url: `${endpoints.instance.instance}/${id}`,
      }),
      providesTags: (_instance, _err, id) => [{ type: "Instances", id }],
    }),
    createInstance: builder.mutation<InstanceType, Partial<CreateInstanceType>>({
      query: (body) => ({
        url: endpoints.instance.create,
        method: "POST",
        body,
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        // Update instance cache list with new item
        try {
          const { data: instanceItem } = await queryFulfilled;
          dispatch(
            instancesApi.util.updateQueryData("getAllInstance", undefined, (draftInstances) => {
              draftInstances.unshift(instanceItem);
            })
          );
        } catch {}
      },
    }),
    createAcmInstance: builder.mutation<InstanceType, Partial<CreateAcmInstanceType>>({
      query: (body) => ({
        url: endpoints.instance.createAcm,
        method: "POST",
        body,
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        // Update instance cache list with new item
        try {
          const { data: instanceItem } = await queryFulfilled;
          dispatch(
            instancesApi.util.updateQueryData("getAllAcmInstance", undefined, (draftInstances) => {
              draftInstances.unshift(instanceItem);
            })
          );
        } catch {}
      },
    }),
    deleteInstance: builder.mutation<string, string>({
      query: (id) => ({
        url: `${endpoints.instance.delete}/${id}`,
        method: "DELETE",
        responseHandler: (response: { text: () => any }) => response.text(),
      }),
    }),
    getAllAcmInstance: builder.query<InstancesResponse, void>({
      query: () => ({
        url: endpoints.instance.acmList,
      }),
      transformResponse: (response: InstancesResponse = []) => {
        if (response === null) return [];
        return response;
      },
      providesTags: (result = []) => {
        return [
          ...result.map(({ instanceID }) => ({ type: "AcmInstances", id: instanceID } as const)),
          { type: "AcmInstances" as const, id: "LIST" },
        ];
      },
    }),
    getAcmInstance: builder.query<InstanceType, string | undefined>({
      query: (id) => ({
        url: `${endpoints.instance.acmInstance}/${id}`,
      }),
      providesTags: (_instance, _err, instanceID) => [{ type: "AcmInstances", id: instanceID }],
    }),
    deleteAcmInstance: builder.mutation<string, string>({
      query: (id) => ({
        url: `${endpoints.instance.deleteAcm}/${id}`,
        method: "DELETE",
        responseHandler: (response: { text: () => any }) => response.text(),
      }),
    }),
    getAcmBranches: builder.query<AcmBranchResponse, void>({
      query: () => ({
        url: endpoints.instance.acmBranches,
      }),
      providesTags: (result = []) => {
        return [
          ...result.map(({ branchName }) => ({ type: "Branches", branchName } as const)),
          { type: "Branches" as const, branchName: "LIST" },
        ];
      },
    }),
  }),
});

export const {
  useGetAllInstanceQuery,
  useGetInstanceQuery,
  useCreateInstanceMutation,
  useCreateAcmInstanceMutation,
  useDeleteInstanceMutation,
  useDeleteAcmInstanceMutation,
  useGetAllAcmInstanceQuery,
  useGetAcmInstanceQuery,
  useGetAcmBranchesQuery,
} = instancesApi;
