import { Box, Stack, Typography } from "@mui/material";
import { HideText } from "components";
import { CheckCircleIcon } from "components/icons";
import { ServiceList, mergePackages, useGetInstanceQuery } from "features";
import { Eye, EyeOff } from "react-feather";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getUserProfile } from "services/userSlice.service";

export function InstanceDetails() {
  const { instanceId } = useParams();
  const { data: instance } = useGetInstanceQuery(instanceId);
  const user = useSelector(getUserProfile);

  return (
    <Box data-testid="instance-details">
      <Typography variant="h3" sx={{ fontSize: "32px", mb: 4 }}>
        {instance?.prettyName}
      </Typography>
      <Typography variant="h5">General Information</Typography>
      <Box sx={{ pt: 2, pb: 1 }}>
        <Typography sx={{ color: "grey.600", pb: 1 }}>Created By</Typography>
        <Typography>{user?.name || "Unknown"}</Typography>
      </Box>
      <Box sx={{ pt: 2, pb: 1 }}>
        <Typography sx={{ color: "grey.600", pb: 1 }}>Created On</Typography>
        <Typography>
          {instance?.createdAt
            ? new Date(instance.createdAt).toLocaleString("en-US", {
                day: "2-digit",
                month: "short",
                year: "numeric",
                hour: "numeric",
                minute: "numeric",
                weekday: "short",
                hour12: false,
              })
            : "N/A"}
        </Typography>
      </Box>
      <Box sx={{ pt: 2, pb: 1 }}>
        <Typography sx={{ color: "grey.600" }}>Status</Typography>
        <Box mr={1} sx={{ display: "flex", alignItems: "center", mt: 1 }}>
          <CheckCircleIcon />
          <Typography sx={{ pl: 1, fontSize: "14px", color: "grey.600" }}>Ready to use</Typography>
        </Box>
      </Box>
      <Box sx={{ mt: 6 }} />
      {instance?.rootPassword && (
        <Box sx={{ mb: 5 }}>
          <Typography variant="h5">Instance credentials</Typography>
          <HideText text={instance?.rootPassword || ""}>
            {(text, hide, toggle) => (
              <Box sx={{ pt: 2, pb: 1 }} data-testid="instance-password">
                <Stack direction="row" spacing={1} alignItems={"center"}>
                  <Typography sx={{ color: "grey.600", pb: 0 }}>Root password</Typography>
                  <Box
                    component={"span"}
                    color="textSecondary"
                    sx={{ pb: 0, cursor: "pointer", "& svg": { width: "18px" } }}
                  >
                    {hide ? <EyeOff onClick={toggle} /> : <Eye onClick={toggle} />}
                  </Box>
                </Stack>
                <Typography sx={{ pt: 1 }}>{text}</Typography>
              </Box>
            )}
          </HideText>
        </Box>
      )}
      <Typography variant="h5" sx={{ pb: 2 }}>
        Service credentials
      </Typography>
      <Box sx={{ pb: 1 }}>
        <Typography sx={{ color: "grey.600", pb: 1 }}>Username</Typography>
        <Typography>{instance?.database?.username || "No username found"}</Typography>
      </Box>
      <HideText text={instance?.database?.password || "No password found"}>
        {(text, hide, toggle) => (
          <Box sx={{ pt: 1, pb: 1 }}>
            <Stack direction="row" spacing={1} alignItems={"center"}>
              <Typography sx={{ color: "grey.600", pb: 0 }}>Password</Typography>
              <Box
                component={"span"}
                color="textSecondary"
                sx={{ pb: 0, cursor: "pointer", "& svg": { width: "18px" } }}
              >
                {hide ? <EyeOff onClick={toggle} /> : <Eye onClick={toggle} />}
              </Box>
            </Stack>
            <Typography sx={{ pt: 1 }}>{text}</Typography>
          </Box>
        )}
      </HideText>
      <Box sx={{ mt: 6 }} data-testid="service-list-container">
        <ServiceList services={mergePackages(instance?.services || [])} />
      </Box>
    </Box>
  );
}
