import { useCallback } from "react";
import { To, useNavigate as useNavigateRouterDOM } from "react-router-dom";

export function useNavigate() {
  const navigate = useNavigateRouterDOM();

  const navigateTo = useCallback(
    (type: "push" | "replace", path: To) => {
      if (type === "push") {
        navigate(path);
      } else {
        navigate(path, { replace: true });
      }
    },
    [navigate]
  );

  return navigateTo;
}
