import { ZoneConfig } from "./types";

export const PLANE_CONFIG: ZoneConfig[] = [
  {
    id: "MDZone1",
    name: "Business",
    columns: 3,
    rows: 5,
    index: 0,
  },
  {
    id: "MDZone2",
    name: "Premium Eco",
    columns: 3,
    rows: 8,
    index: 1,
  },
  {
    id: "MDZone3",
    name: "Economy",
    columns: 3,
    rows: 19,
    index: 2,
  },
];

export const PLANE_COLUMNS = ["A", "B", "C", "D", "E", "F"];
