import ButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import Stack from "@mui/material/Stack";
import { PLANE_COLUMNS, PLANE_CONFIG } from "./utils";
import { cumulateToIndex } from "utils/array";
import Typography from "@mui/material/Typography";
import { Seat } from "./Seat";
import Box from "@mui/material/Box";
import { useGetSeatQuery, useUpdateSeatMutation } from "./services/seatId.service";
import Alert from "@mui/material/Alert";
import { useCallback } from "react";
import { SeatInfo } from "./types";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

export function SeatIdService({ url }: { url: string }) {
  const { data, isFetching, error } = useGetSeatQuery(url);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const [updateSeat, { error: updateError }] = useUpdateSeatMutation();

  const update = useCallback(
    (info: Partial<SeatInfo>) => {
      updateSeat({
        url,
        body: info,
      });
    },
    [url, updateSeat]
  );

  if (isFetching) {
    return <Typography>Loading...</Typography>;
  }

  if (error || updateError) {
    return (
      <>
        <Alert severity="error">
          <Typography>Something went wrong!</Typography>
        </Alert>
        <Typography mt={2}>Error:</Typography>
        <Typography data-testid="errorDescription" component="pre">
          {JSON.stringify(error || updateError)}
        </Typography>
      </>
    );
  }

  return (
    <div>
      <Stack data-testid="seat-service" alignItems="center" spacing={3}>
        <ButtonGroup orientation={!matches ? "vertical" : "horizontal"} exclusive value={data?.zone}>
          {PLANE_CONFIG.map((zone) => (
            <ToggleButton
              href={`#${zone.name}`}
              sx={{ textTransform: "none", px: 3, py: 2 }}
              value={zone.id}
              key={zone.id}
              disableRipple
            >
              {zone.name}
            </ToggleButton>
          ))}
        </ButtonGroup>
        <Box width="100%" position="relative" sx={{ overflow: "auto" }} px={4} pl={5} height={560} maxWidth={650}>
          <Stack spacing={3} maxHeight={560} position="absolute">
            {PLANE_CONFIG.map((zone, index, allZones) => {
              const columns = Array(zone.columns).fill(0);
              const prevRowNumber = cumulateToIndex(allZones, index - 1, (sum, zone) => sum + zone.rows, 0);
              const rows = Array(zone.rows)
                .fill(0)
                .map((_, i) => i + prevRowNumber + 1);
              return (
                <Stack minWidth={0} key={zone.id} direction="column" spacing={2} alignItems="center">
                  <Typography id={zone.name} variant="h3">
                    {zone.name}
                  </Typography>
                  {rows.map((row) => (
                    <Stack minWidth={0} key={row} direction="row" spacing={2} alignItems="center">
                      {columns.map((_, col) => (
                        <Seat
                          onClick={() =>
                            update({
                              rowNumber: String(row),
                              seatId: `${row}${PLANE_COLUMNS[col]}`,
                              zone: zone.id,
                            })
                          }
                          selected={`${row}${PLANE_COLUMNS[col]}` === data?.seatId}
                          key={PLANE_COLUMNS[col]}
                          letter={PLANE_COLUMNS[col]}
                        />
                      ))}
                      <Box
                        width={52}
                        height={44}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        position="relative"
                      >
                        <Typography mb={1} variant="h4" fontWeight={500} fontSize="1.8rem">
                          {row}
                        </Typography>
                      </Box>
                      {columns.map((_, col) => (
                        <Seat
                          onClick={() =>
                            update({
                              rowNumber: String(row),
                              seatId: `${row}${PLANE_COLUMNS[col + columns.length]}`,
                              zone: zone.id,
                            })
                          }
                          selected={`${row}${PLANE_COLUMNS[col + columns.length]}` === data?.seatId}
                          key={PLANE_COLUMNS[col + columns.length]}
                          letter={PLANE_COLUMNS[col + columns.length]}
                        />
                      ))}
                    </Stack>
                  ))}
                </Stack>
              );
            })}
          </Stack>
        </Box>
        <Stack width="100%" direction="row" spacing={1}>
          <Typography>Current seat selection:</Typography>
          <Typography>{data?.seatId || "no seat selected."}</Typography>
        </Stack>
      </Stack>
    </div>
  );
}
