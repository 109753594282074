import React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import { SearchIcon } from "../icons";

interface PropsType {
  searchText: string;
  onSearch: (value: string) => void;
}

export function Searchbar({ onSearch, searchText }: PropsType) {
  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    onSearch(event.target.value);
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      component="form"
      sx={{ ml: 2, width: { md: "400px", sm: "300px", xs: "200px" } }}
    >
      <TextField
        id="outlined-start-adornment"
        value={searchText}
        fullWidth
        onChange={onChangeHandler}
        placeholder="Search"
        data-testid="instance-search-box"
        sx={{ "& input": { height: "13px" } }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
}
