import { VPN_API_BASE_URL } from "constant";
interface DeviceCodeService {
  send(code: number, instanceId: string, userId: string): Promise<string>;
  getConfig(file: string): Promise<Response>;
  setAuth(token: string): void;
}
export class HttpDeviceCodeService implements DeviceCodeService {
  private auth = "";

  setAuth(token: string): void {
    this.auth = token;
  }

  async send(code: number, instanceId: string, userId: string): Promise<string> {
    const response = await fetch(`${VPN_API_BASE_URL}/create-config`, {
      headers: {
        "Content-type": "application/json",
        Authorization: this.auth,
      },
      method: "POST",
      body: JSON.stringify({
        deviceCode: code,
        instanceId,
        userId,
      }),
    });

    if (!response.ok) {
      throw Error("Unknown error!");
    }

    const { file_name } = await response.json();

    return file_name;
  }

  async getConfig(file: string): Promise<Response> {
    return await fetch(`${VPN_API_BASE_URL}/download/${file}`, {
      headers: {
        Authorization: this.auth,
      },
    });
  }
}

export const deviceCodeService = new HttpDeviceCodeService();
