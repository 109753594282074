import Dialog, { DialogProps } from "@mui/material/Dialog";
import React, { ReactNode, useCallback, useState } from "react";

export type ModalControl = { onOpen: () => void; onClose: () => void };

export function Modal({
  children,
  dialogProps,
}: {
  children: (control: ModalControl) => ReactNode;
  dialogProps: (control: ModalControl) => Omit<DialogProps, "open">;
}) {
  const [open, setOpen] = useState(false);

  const onOpen = useCallback(() => setOpen(true), [setOpen]);
  const onClose = useCallback(() => setOpen(false), [setOpen]);

  return (
    <>
      <Dialog
        open={open}
        {...dialogProps({
          onClose,
          onOpen,
        })}
      ></Dialog>
      {children({
        onClose,
        onOpen,
      })}
    </>
  );
}
