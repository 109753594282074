export abstract class Router {
  static overview() {
    return {
      wildcard() {
        return "/home/*";
      },
      instances() {
        return "/home";
      },
      acmInstances() {
        return "/home/acm-instances";
      },
    };
  }

  static settings() {
    return {
      index() {
        return "/settings";
      },
      general() {
        return "/settings/general";
      },
      access() {
        return "/settings/access";
      },
      wildcard() {
        return "/settings/*";
      },
    };
  }

  static analytics() {
    return "/analytics";
  }

  static instance() {
    return {
      create() {
        return "/instance/create";
      },
      details(id?: string) {
        return `/instance/${id || ":instanceId"}/details`;
      },
      services(id?: string) {
        return `/instance/${id || ":instanceId"}/services`;
      },
      logs(id?: string) {
        return `/instance/${id || ":instanceId"}/logs`;
      },
      wildcard(id?: string) {
        return `/instance/${id || ":instanceId"}/*`;
      },
    };
  }
  static acmInstance() {
    return {
      create() {
        return "/acm-instance/create";
      },
      details(id?: string) {
        return `/acm-instance/${id || ":instanceId"}/details`;
      },
      services(id?: string) {
        return `/acm-instance/${id || ":instanceId"}/services`;
      },
      logs(id?: string) {
        return `/acm-instance/${id || ":instanceId"}/logs`;
      },
      wildcard(id?: string) {
        return `/acm-instance/${id || ":instanceId"}/*`;
      },
    };
  }
  static simulator() {
    return {
      open(id?: string) {
        return `/simulator/${id || ":instanceId"}/open`;
      },
    };
  }
}
