import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { ReactElement } from "react";

export function MainLayout({ children, sidebar }: { children?: ReactElement; sidebar?: ReactElement }) {
  return (
    <Grid flex={1} container>
      <Grid item display="flex">
        {sidebar}
      </Grid>
      <Grid m={4} mb={0} flex={1} item>
        {children}
      </Grid>
    </Grid>
  );
}
