import * as React from "react";
import { Breadcrumbs as MuiBreadcrumbs } from "@mui/material";
import { Link } from "components/link";

export interface IBreadcumbs {
  link: string;
  title: string;
  active?: boolean;
}

const Breadcrumbs = ({ linkList }: { linkList: IBreadcumbs[] }) => {
  if (!linkList || linkList.length < 2) {
    return null;
  }
  return (
    <MuiBreadcrumbs aria-label="breadcrumb" data-testid="breadcrumbs-container" sx={{ pb: 3, fontSize: "20px" }}>
      {linkList.map((linkItem: IBreadcumbs) => (
        <Link
          key={linkItem.link}
          sx={{ "&:hover": { textDecoration: "none", color: "text.primary" } }}
          underline="hover"
          color={linkItem.active ? "text.primary" : "inherit"}
          aria-current={linkItem.active ? "page" : false}
          href={linkItem.link}
        >
          {linkItem.title}
        </Link>
      ))}
    </MuiBreadcrumbs>
  );
};

export { Breadcrumbs };
