import { INSTANCE_STATUS } from "constant";
import { StatusType } from "../hooks";
import { isEmpty } from "lodash";
import { DeployedService, PackageServiceType } from "../types";

const READABLE_SERVICE_NAME = {
  fapi: "Fapi",
  cms: "CMS",
  dev: "Dev Machine",
  rmq: "RabbitMQ",
  postgres: "Postgress Database",
  mongo: "MongoDB",
  ads: "Ad Service",
  paxfe: "PAXFE",
  seatid: "Seat ID",
};

export const getReadableServiceName = (serviceName: string) => {
  const keyName = serviceName as keyof typeof READABLE_SERVICE_NAME;
  return READABLE_SERVICE_NAME[keyName] || "";
};

const getStatusResponse = (svcName: string, svcStates: any) => {
  const colors = {
    [INSTANCE_STATUS.ERROR]: "#DF3F41",
    [INSTANCE_STATUS.WARNING]: "#DF953F",
    [INSTANCE_STATUS.READY]: "inherit",
    [INSTANCE_STATUS.CREATING]: "inherit",
    [INSTANCE_STATUS.PREPARING]: "inherit",
  };
  return {
    color: svcStates?.[svcName] ? colors[svcStates?.[svcName].status as keyof typeof colors] : "inherit",
    message: svcStates?.[svcName]?.message,
    disabled: svcStates?.[svcName]?.status === INSTANCE_STATUS.CREATING,
    isWarning:
      svcStates?.[svcName]?.status === INSTANCE_STATUS.WARNING ||
      svcStates?.[svcName]?.status === INSTANCE_STATUS.ERROR,
  };
};

export const getServiceState = (svcName: string, instanceStatus: StatusType) => {
  const defaultResponse = { color: "inherit", message: "", disabled: false, isWarning: false };

  if (instanceStatus.status === INSTANCE_STATUS.READY && isEmpty(instanceStatus.svcStates)) {
    return defaultResponse;
  }

  const { svcStates } = instanceStatus;

  switch (svcName) {
    case "rabbitmq":
    case "rabbitmq-admin": {
      return getStatusResponse("rmq", svcStates);
    }
    case "Dev-SSH":
    case "dev": {
      return getStatusResponse("dev", svcStates);
    }
    case "postgres": {
      return getStatusResponse("postgres", svcStates);
    }
    case "mongo": {
      return getStatusResponse("mongo", svcStates);
    }
    case "redis": {
      return getStatusResponse("redis", svcStates);
    }
    case "flightctx": {
      return getStatusResponse("flightctx", svcStates);
    }
    case "paxfe": {
      return getStatusResponse("paxfe", svcStates);
    }
    case "cms": {
      return getStatusResponse("cms", svcStates);
    }
    case "fapi": {
      return getStatusResponse("fapi", svcStates);
    }
    case "ads-server": {
      return getStatusResponse("ads", svcStates);
    }
    case "seatid": {
      return getStatusResponse("seatid", svcStates);
    }
    default: {
      return defaultResponse;
    }
  }
};

export const mergePackages = (services: DeployedService[]) => {
  return services.reduce((p: PackageServiceType[], service: DeployedService) => {
    const _packages = service?.packages ? service.packages : [];
    return [...p, ..._packages];
  }, []);
};
