import { CustomButton } from "@control-tower/aerq-ui-library";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { Router } from "constant/router";
import { useGetAcmInstanceQuery, useGetInstanceQuery } from "features/instances";
import { useParams, Navigate, useSearchParams } from "react-router-dom";

export function OpenSimulator() {
  const { instanceId } = useParams();
  const [searchParams] = useSearchParams();
  const instanceType = searchParams.get("type");

  const { data: acmInstance, isLoading: acmLoading } = useGetAcmInstanceQuery(instanceId, {
    skip: instanceType === "vtr",
  });

  const { data: vtrInstance, isLoading: vtrLoading } = useGetInstanceQuery(instanceId, {
    skip: instanceType === "acm",
  });

  const instance = instanceType === "vtr" ? vtrInstance : acmInstance;
  const isLoading = instanceType === "vtr" ? vtrLoading : acmLoading;

  if (isLoading) {
    return (
      <Box display="flex" height="100%" alignItems="center" justifyContent="center" data-testid="loader">
        <CircularProgress />
      </Box>
    );
  }

  if (!instance) {
    return <Navigate to={Router.overview().instances()} />;
  }

  const systemUi = instance?.services.find((s) => s.name === "systemui")?.packages[0];
  const paxfe = instance.services.find((s) => s.name === "paxfe" || s.name === "pax-frontend")?.packages[0];

  return (
    <Box display="flex" height="100%" alignItems="center" justifyContent="center" data-testid="container">
      <Stack spacing={4} alignItems="center">
        <Typography align="center">
          Click <b>Open simulator</b> on the dialog shown by your browser <br /> If you don't see a dialog, click{" "}
          <b>Launch Simulator</b> below
        </Typography>
        <CustomButton href={`system-ui://${systemUi?.url}/simulator?appUrl=${paxfe?.url}`} data-testid="launch-btn">
          Launch Simulator
        </CustomButton>
        <Typography align="center">
          Alternatively, you can also
          <Link
            href={`${systemUi?.url}/simulator?appUrl=${paxfe?.url}`}
            target="_blank"
            sx={{
              ml: 1,
              fontWeight: 600,
              display: "inline-block",
              color: (theme) => `${theme.palette.primary.main} !important`,
            }}
            underline="none"
          >
            Launch From Browser
          </Link>
        </Typography>
        <Typography variant="caption">
          Don't have Simulator?
          <Link
            // href={downloadLink}
            target="_blank"
            sx={{
              ml: 1,
              display: "inline-block",
              fontWeight: 600,
              color: (theme) => `${theme.palette.primary.main} !important`,
            }}
            underline="none"
          >
            Install Now
          </Link>
        </Typography>
      </Stack>
    </Box>
  );
}
