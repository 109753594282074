import {
  AERQNavigation,
  AuthorizationError,
  OrganizationSelector,
  useKeycloak,
  useProfileState,
} from "@control-tower/aerq-navigation-library";
import { PageTemplate } from "@control-tower/aerq-ui-library";
import makeStyles from "@mui/styles/makeStyles";
import { Header } from "components";
import { ErrorBoundary } from "components/errorBoundary";
import { MainLayout, Sidebar } from "components/layouts";
import { KEYCLOAK_CLIENT_ID, KEYCLOAK_REALM, KEYCLOAK_URL, USER_MANAGEMENT_WEB_API_URL } from "constant";
import { InstanceNavigation, OpenSimulator } from "features";
import { OverviewNavigation } from "features/overview";
import { setAuthToken } from "lib";
import { pick } from "lodash";
import {
  Access,
  CreateInstance,
  CreateAcmInstance,
  General,
  Home,
  InstanceDetails,
  Instance,
  Settings,
  VtrInstances,
  AcmInstances,
  AcmInstance,
  AcmInstanceDetails,
} from "pages";
import { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link, Navigate, Outlet, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { addUser } from "services/userSlice.service";
import "./App.css";
import { Router } from "constant/router";
import { useSocketConnection } from "lib/@websocket";

function App() {
  const navigateTo = useNavigate();
  const handleAERQNavigationError = useCallback((error: any) => {
    if (error instanceof AuthorizationError) {
      // TODO: messagesDispatch(showErrorDialog(401, [{ code: 'NO_PERMISSION' }]));
      console.log("Nav lib error ", "NO_PERMISSION");
      return;
    }

    if (error.response) {
      const { data, status } = error.response;
      console.log("Nav lib error ", status, data.messages);
      // TODO: messagesDispatch(showErrorDialog(status, data.messages));
      return;
    }

    if (error.request) {
      console.log("Nav lib error ", 404);
      // TODO: messagesDispatch(showErrorDialog(404));
      return;
    }

    console.log("Nav lib error");
    // TODO:  messagesDispatch(showErrorDialog());
  }, []);

  return (
    <AERQNavigation
      keycloakOptions={{
        url: KEYCLOAK_URL,
        realm: KEYCLOAK_REALM as string,
        clientId: KEYCLOAK_CLIENT_ID as string,
      }}
      onAfterSelectProfile={(orgInfo: { profileOrgId?: string }, keycloak: import("keycloak-js").KeycloakInstance) => {
        if (keycloak) {
          setAuthToken(keycloak);
        }
      }}
      notificationServiceURL={""}
      userManagementWebApiURL={USER_MANAGEMENT_WEB_API_URL as string}
      ErrorComponent={undefined}
      onError={handleAERQNavigationError}
      navigateTo={navigateTo}
      disableNotificationsForCurrent
    >
      <MainContent />
    </AERQNavigation>
  );
}

const useStyles = makeStyles(() => ({
  pageLayout: {
    minHeight: "100vh",
  },
}));

const MainContent = () => {
  const navigateTo = useNavigate();
  const location = useLocation();
  const profile = useProfileState();
  const keycloakData = useKeycloak();
  const classes = useStyles();
  const dispatch = useDispatch();

  useSocketConnection();

  useEffect(() => {
    if (profile) {
      dispatch(
        addUser({
          subject: keycloakData.subject,
          profile: profile.profile,
          ...pick(keycloakData.tokenParsed, ["preferred_username"]),
        })
      );
    }
  }, [profile, keycloakData]);

  return (
    <PageTemplate className={classes.pageLayout} stickyFooter={true}>
      <Header />
      <ErrorBoundary>
        <MainLayout
          sidebar={
            <Routes>
              <Route
                path="/"
                element={
                  <Sidebar>
                    <Outlet />
                  </Sidebar>
                }
              >
                <Route index path={Router.overview().wildcard()} element={<OverviewNavigation />} />
                <Route path={Router.analytics()} element={<OverviewNavigation />} />
                <Route path={Router.settings().wildcard()} element={<OverviewNavigation />} />
              </Route>
              <Route path={Router.instance().create()} element={null} />
              <Route path={Router.acmInstance().create()} element={null} />
              <Route
                path={Router.instance().wildcard()}
                element={
                  <Sidebar>
                    <InstanceNavigation />
                  </Sidebar>
                }
              />
              <Route
                path={Router.acmInstance().wildcard()}
                element={
                  <Sidebar>
                    <InstanceNavigation isAcmInstance={true} />
                  </Sidebar>
                }
              />
            </Routes>
          }
        >
          <Routes>
            <Route
              path="/organization-selector"
              element={
                <OrganizationSelector
                  onAfterSelectProfile={() => {
                    // no-op
                  }}
                  userManagementWebApiURL={USER_MANAGEMENT_WEB_API_URL as string}
                  notificationServiceURL=""
                  clientId={KEYCLOAK_CLIENT_ID as string}
                  navigateTo={navigateTo}
                  location={location}
                  Link={Link}
                />
              }
            />
            <Route path={Router.overview().instances()} element={<Home />}>
              <Route index element={<VtrInstances />} />
              <Route path={Router.overview().acmInstances()} element={<AcmInstances />} />
            </Route>
            <Route path={Router.settings().index()} element={<Settings />}>
              <Route index element={<Navigate replace to={Router.settings().general()} />} />
              <Route path={Router.settings().general()} element={<General />} />
              <Route path={Router.settings().access()} element={<Access />} />
              <Route />
            </Route>
            <Route path={Router.analytics()} element={<div>Analytics coming soon...</div>} />
            <Route path={Router.instance().create()} element={<CreateInstance />} />
            <Route path={Router.instance().details()} element={<InstanceDetails />} />
            <Route path={Router.instance().services()} element={<Instance />} />
            <Route path={Router.instance().logs()} element={<div>Logs coming soon...</div>} />

            <Route path={Router.acmInstance().create()} element={<CreateAcmInstance />} />
            <Route path={Router.acmInstance().services()} element={<AcmInstance />} />
            <Route path={Router.acmInstance().details()} element={<AcmInstanceDetails />} />
            <Route path={Router.acmInstance().logs()} element={<div>Logs coming soon...</div>} />

            <Route path={Router.simulator().open()} element={<OpenSimulator />} />
            <Route path="*" element={<Navigate replace to={Router.overview().instances()} />} />
          </Routes>
        </MainLayout>
      </ErrorBoundary>
    </PageTemplate>
  );
};

export default App;
