import { Typography, Box } from "@mui/material";
import { CheckCircleIcon } from "components/icons";
import { AlertCircle } from "react-feather";
import { StatusType } from "../hooks";
import { INSTANCE_STATUS } from "constant";

export function InstanceStatus({ instanceStatus }: { instanceStatus: StatusType }) {
  const style = {
    display: "flex",
    alignItems: "center",
    mr: 1,
  };

  const colors = {
    [INSTANCE_STATUS.ERROR]: "#DF3F41",
    [INSTANCE_STATUS.WARNING]: "#DF953F",
  };

  if (instanceStatus.status === INSTANCE_STATUS.READY) {
    return (
      <Box style={style} data-testid="ready-status">
        <CheckCircleIcon />
        <Typography sx={{ pl: 1, fontSize: "14px", color: "grey.600" }}>{instanceStatus.shortMessage}</Typography>
      </Box>
    );
  }

  if (instanceStatus.status === INSTANCE_STATUS.CREATING) {
    return (
      <Box style={style} data-testid="creating-status">
        <Box
          component={"span"}
          sx={{
            display: "inline-block",
            width: "14px",
            height: "14px",
            borderRadius: "100%",
            backgroundColor: "#F59601",
          }}
        />
        <Typography sx={{ pl: 1, fontSize: "14px", color: "grey.600" }}>{instanceStatus.shortMessage}</Typography>
      </Box>
    );
  }

  if (instanceStatus.status === INSTANCE_STATUS.ERROR || instanceStatus.status === INSTANCE_STATUS.WARNING) {
    const errorColor = colors[instanceStatus.status];
    return (
      <Box
        sx={{ ...style, "& svg": { width: "20px", stroke: "#ffffff", fill: errorColor } }}
        data-testid="warning-status"
      >
        <AlertCircle />
        <Typography sx={{ pl: 1, fontSize: "14px", color: errorColor }}>{instanceStatus.shortMessage}</Typography>
      </Box>
    );
  }

  return (
    <Box style={style} data-testid="preparing-status">
      <Box
        component={"span"}
        sx={{
          display: "inline-block",
          width: "18px",
          height: "18px",
          borderRadius: "100%",
          backgroundColor: "#F59601",
        }}
      />
      <Typography sx={{ pl: 1, fontSize: "14px", color: "grey.600" }}>{instanceStatus.shortMessage}</Typography>
    </Box>
  );
}
