import { useCallback } from "react";
import Alert from "@mui/material/Alert";
import Typography from "@mui/material/Typography";
import { FapiForm } from "./FapiForm";
import { useGetFapiQuery, useUpdateFapiMutation } from "./services/fapi.service";
import { AIRPORTS } from "./utils";

const FapiService = ({ url }: { url: string }) => {
  const fapiResult = useGetFapiQuery(url);
  const { data: fapiData, isFetching, error } = fapiResult;
  const [updateFapi, result] = useUpdateFapiMutation();
  const { error: updateError, isLoading } = result;

  const onUpdateFapi = useCallback(
    async (newData: any) => {
      updateFapi({ url, body: newData });
    },
    [url]
  );

  const title = <Typography variant="h4">Fapi controls</Typography>;
  if (isFetching) {
    return (
      <>
        {title}
        <Typography>Loading...</Typography>
      </>
    );
  }

  if (error || !fapiData || updateError) {
    return (
      <>
        {title}
        <Alert severity="error">
          <Typography>Something went wrong!</Typography>
        </Alert>
        <Typography mt={2}>Error:</Typography>
        <Typography data-testid="errorDescription" component="pre">
          {error ? JSON.stringify(error) : "No FAPI data!"}
        </Typography>
      </>
    );
  }

  return <FapiForm data={fapiData} onUpdate={onUpdateFapi} isUpdating={isLoading} airports={AIRPORTS} />;
};

export { FapiService };
