import React, { ReactNode, useState, useCallback, useEffect } from "react";

export function CopyToClipboard({
  children,
  label = "Copy to clipboard",
  message = "Copied!",
  value,
}: {
  children: (copy: () => void, text: string) => ReactNode;
  label?: string;
  message?: string;
  value: string | undefined;
}) {
  const [text, setText] = useState(label);

  useEffect(() => {
    setText(label);
  }, [label, setText]);

  const copy = useCallback(async () => {
    if (!value) {
      return;
    }
    navigator.clipboard.writeText(value);
    setText(message);
    await new Promise((resolve) => setTimeout(resolve, 750));
    setText(label);
  }, [setText, message, value]);

  return <span>{children(copy, text)}</span>;
}
