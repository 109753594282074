const showLoadingOverlay = () => {
  (document.getElementById("loading-mask") as HTMLElement).style.display = "block";
  (document.getElementById("loading-mask-icon") as HTMLElement).style.display = "block";
};

const hideLoadingOverlay = () => {
  (document.getElementById("loading-mask") as HTMLElement).style.display = "none";
  (document.getElementById("loading-mask-icon") as HTMLElement).style.display = "none";
};

export { showLoadingOverlay, hideLoadingOverlay };
