import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Router } from "constant/router";
import { useState } from "react";
import { useParams } from "react-router-dom";
import Tabs from "@mui/material/Tabs";
import { CustomTab } from "theme/elements";
import { FapiControl } from "lib";
import { Breadcrumbs, SectionHeader, TabPanel } from "components";
import { useInstanceStatus } from "features/instances/hooks";
import { ServiceSidebar, useGetAcmInstanceQuery } from "features";
import { SeatIdControl } from "lib/@seatId";

export function AcmInstance() {
  const { instanceId } = useParams();
  const { data: instance, isFetching } = useGetAcmInstanceQuery(instanceId);
  const [value, setValue] = useState(0);
  const instanceStatus = useInstanceStatus(instance?.services || []);
  const fapiState = { color: "inherit", message: "", disabled: false, isWarning: false };
  const seatIdState = { color: "inherit", message: "", disabled: false, isWarning: false };

  if (isFetching) {
    return null;
  }

  return (
    <Box m={-4}>
      <Grid container>
        <Grid flex={1} item>
          <Box m={4} sx={{ height: "100%" }} className={"main-container"} data-testid="instance-details-container">
            <Breadcrumbs
              linkList={[
                { link: Router.overview().acmInstances(), title: "Overview" },
                { link: Router.acmInstance().services(instance?.instanceID), active: true, title: "Services" },
              ]}
            />
            <SectionHeader>
              <Typography variant="h1" data-testid="service-title">
                Services
              </Typography>
            </SectionHeader>
            <Box>
              <Tabs variant="standard" value={value} aria-label="service tab" orientation="horizontal">
                <CustomTab
                  disableRipple
                  value={0}
                  data-testid="fapi-tab"
                  className="tab-warning"
                  label="FAPI"
                  onClick={() => setValue(0)}
                  color="textSecondary"
                  sx={{
                    color: `${value === 0 ? fapiState.color : "#9E9E9E"} !important`,
                    textTransform: "none",
                    "& svg": {
                      marginRight: "5px",
                      fill: fapiState.color,
                      stroke: "#ffffff",
                    },
                  }}
                />
                <CustomTab
                  disableRipple
                  value={1}
                  data-testid="seat-id-tab"
                  icon={undefined}
                  className="tab-warning"
                  label="Seat ID"
                  onClick={() => setValue(1)}
                  color=""
                  sx={{
                    color: `${value === 1 ? seatIdState?.color : "#9E9E9E"} !important`,
                    "& svg": {
                      marginRight: "5px",
                      fill: seatIdState?.color,
                      stroke: "#ffffff",
                    },
                    textTransform: "none",
                  }}
                />
              </Tabs>
            </Box>
            <Box>
              <TabPanel value={value} index={0} pt={4}>
                <FapiControl services={instance?.services} />
              </TabPanel>

              <TabPanel value={value} index={1} pt={4}>
                <SeatIdControl services={instance?.services} />
              </TabPanel>
            </Box>
          </Box>
        </Grid>
        <Grid item display="flex" sx={{ borderColor: "divider", borderLeftWidth: 1, borderLeftStyle: "solid" }}>
          <ServiceSidebar services={instance?.services || []} status={instanceStatus} />
        </Grid>
      </Grid>
    </Box>
  );
}
