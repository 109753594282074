import React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import { themeCompact } from "@control-tower/aerq-ui-library";

const GroundThemeProvider = ({ children }: { children: React.ReactNode }) => (
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={themeCompact}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  </StyledEngineProvider>
);

export default GroundThemeProvider;
