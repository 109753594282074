import { useRef, useMemo } from "react";
import { useAuthConfState } from "@control-tower/aerq-navigation-library/dist/auth/state/Auth/authContext";
import { isEqual } from "lodash";

const useArrayMemo = (list: any) => {
  const result = useRef([...list]);

  if (!isEqual(list, result.current)) {
    result.current = [...list];
  }

  return result.current;
};

const usePermissions = (...requiredPrivileges: any) => {
  const {
    authData: { roles: userRoles },
  } = useAuthConfState();
  const requiredPriviligesMemo = useArrayMemo(requiredPrivileges);

  const availablePrivileges = useMemo(
    () => requiredPriviligesMemo.map((role) => userRoles.includes(role)),
    [requiredPriviligesMemo, userRoles]
  );

  return availablePrivileges;
};

export { usePermissions };
