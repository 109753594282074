import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store";
export interface IUserStates {
  profile?: any;
  subject?: string;
  preferred_username?: string;
}

const initialState: IUserStates = {
  profile: {},
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    addUser: (state, action: PayloadAction<any>) => {
      return action.payload;
    },
  },
});

export const { addUser } = userSlice.actions;
export const getUser = (state: RootState) => state.user;
export const getUserProfile = (state: RootState) => state.user?.profile;

export default userSlice;
