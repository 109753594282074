import { useEffect } from "react";
import io from "socket.io-client";
import { API_SOCKET_URL } from "constant";
import { getAccessToken } from "lib";
import { InstanceType, InstancesResponse, instancesApi } from "features";
import { useDispatch } from "react-redux";
import { Draft } from "@reduxjs/toolkit";

export const useSocketConnection = () => {
  const dispatch = useDispatch<any>();
  const token = getAccessToken();

  const updateInstanceStatus = async (statusData: any) => {
    const allQueryKey = statusData.type === "acm" ? "getAllAcmInstance" : "getAllInstance";
    const singleQueryKey = statusData.type === "acm" ? "getAcmInstance" : "getInstance";
    dispatch(
      instancesApi.util.updateQueryData(allQueryKey, undefined, (draftList: Draft<InstancesResponse>) => {
        if (draftList) {
          draftList.map((instance) => {
            if (instance.instanceID === statusData.instanceID) {
              instance.services = statusData.services;
            }
            return instance;
          });
        }
      })
    );
    dispatch(
      instancesApi.util.updateQueryData(singleQueryKey, statusData.instanceId, (draftData: Draft<InstanceType>) => {
        if (draftData?.instanceID === statusData.instanceID) {
          draftData.status = statusData.status;
        }
      })
    );
  };

  const onConnect = () => {
    console.log("Socket connected");
  };

  const onDisconnect = () => {
    console.log("Socket disconnected");
  };

  const onSocketConnectionError = (err: any) => {
    console.warn("socket connection error: ", err.message);
  };

  useEffect(() => {
    if (token) {
      const _token = token.split(" ")[1];
      const socket = io(API_SOCKET_URL, {
        transports: ["websocket"],
        query: {
          token: _token,
        },
      });

      socket.on("connect", onConnect);

      socket.on("disconnect", onDisconnect);

      socket.on("status_update", updateInstanceStatus);

      socket.on("connect_error", onSocketConnectionError);

      return () => {
        socket.off("connect", onConnect);
        socket.off("disconnect", onDisconnect);
        socket.off("status_update", updateInstanceStatus);
        socket.off("connect_error", onSocketConnectionError);
      };
    }
  }, [token]);

  return null;
};
