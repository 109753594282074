import { api } from "lib";
import { FAPIData } from "../types";

export const fapiApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getFapi: builder.query<FAPIData, string>({
      query: (url) => ({ url: url + "/api/flight-data" }),
      providesTags: ["Fapi"],
    }),
    updateFapi: builder.mutation<string, { url: string; body: Partial<FAPIData> }>({
      query: (args) => ({
        url: args.url + "/api/mock-data",
        method: "POST",
        body: args.body,
        responseHandler: (response: { text: () => any }) => response.text(),
      }),
    }),
  }),
});

export const { useGetFapiQuery, useUpdateFapiMutation } = fapiApi;
