import { Box, Link, Typography } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { CopyToClipboard, TreeItem } from "components";
import { CopyIconV2 } from "components/icons";
import { ServiceList } from "./ServiceList";
import { DeployedService } from "../types";
import { AlertCircle, ExternalLink } from "react-feather";
import { StatusType } from "../hooks";
import { getServiceState, mergePackages } from "../utils";

interface Props {
  services?: DeployedService[];
  status: StatusType;
}

const RenderItem = ({ item, status }: { item: TreeItem; status: StatusType }) => {
  const linkWhiteListItem = ["rabbitmq", "ssh", "postgres", "mongo", "redis"];
  if ("visible" in item && item.visible === false) {
    return null;
  }
  const svcState = getServiceState(item.id, status);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        pb: 2,
        pt: 1,
        position: "relative",
      }}
    >
      {svcState.isWarning && (
        <Box
          sx={{
            position: "absolute",
            left: "-27px",
            cursor: "pointer",
            bottom: "15px",
            "& svg": { fill: svcState.color, stroke: "white" },
          }}
        >
          <Tooltip
            title={svcState.message}
            PopperProps={{ sx: { "& .MuiTooltip-tooltip": { backgroundColor: svcState.color } } }}
          >
            <AlertCircle />
          </Tooltip>
        </Box>
      )}
      <Box
        sx={{
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          mr: 2,
          position: "relative",
          overflow: "hidden",
          color: svcState.color,
        }}
      >
        <Tooltip title={item.label}>
          <Typography component={"span"} textTransform={"capitalize"}>
            {item.label}
          </Typography>
        </Tooltip>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center", "& svg": { width: "20px" } }}>
        {!linkWhiteListItem.includes(item.id) && !linkWhiteListItem.includes(item?.protocol || "") && (
          <Link
            target="_blank"
            href={item.url}
            mr={0.5}
            sx={{
              pointerEvents: svcState.disabled ? "none" : "all",
              flex: 1,
              "& svg": {
                width: "20px",
                cursor: svcState.disabled ? "not-allowed" : "pointer",
                color: svcState.disabled ? "#BDBDBD" : "#000000",
              },
            }}
          >
            <ExternalLink />
          </Link>
        )}
        <CopyToClipboard value={svcState.disabled ? undefined : item.url}>
          {(copy, text) => (
            <Tooltip title={text}>
              <Box
                onClick={copy}
                component="span"
                sx={{
                  "& svg": {
                    stroke: svcState.disabled ? "#f6f6f6" : "inherit",
                    cursor: svcState.disabled ? "not-allowed" : "pointer",
                  },
                }}
              >
                <CopyIconV2 />
              </Box>
            </Tooltip>
          )}
        </CopyToClipboard>
      </Box>
    </Box>
  );
};

const ServiceSidebar = ({ services = [], status }: Props) => {
  return (
    <Box
      data-testid="service-container"
      sx={{
        width: "360px",
        pb: 4,
        pt: 4,
        "& .MuiAccordion-root": { borderBottom: "1px solid #eeeeee" },
        " & .service-list-accordion-summary": { flexDirection: "inherit", pr: 4, pb: 1, pt: 1 },
        " & .MuiAccordionDetails-root": { pr: 4 },
        " & .MuiAccordionSummary-content": { marginLeft: "0 !important", pl: 4 },
        " & .MuiAccordionSummary-content p": { fontWeight: 600 },
      }}
    >
      <ServiceList
        services={mergePackages(services)}
        header={false}
        defaultExpanded={true}
        renderItem={(_, item) => <RenderItem item={item} status={status} />}
      />
    </Box>
  );
};

export { ServiceSidebar };
