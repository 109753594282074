import { CalloutSection } from "@control-tower/aerq-ui-library";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { DEV_PORTAL_LINK } from "constant";
import { ReactNode } from "react";

export function ServiceCredentialComponent({ children }: { children: ReactNode }) {
  return (
    <Stack spacing={3} mt={6}>
      <Typography variant="h6" color="textSecondary">
        Service credentials
      </Typography>

      <CalloutSection>
        <Typography fontWeight={500}>
          This will apply to all new instances created as the default credentials, you can change it individually later.
          <Link
            href={DEV_PORTAL_LINK}
            target="_blank"
            sx={{
              ml: 1,
              display: "inline-block",
              color: (theme) => `${theme.palette.primary.main} !important`,
            }}
            underline="none"
          >
            Learn more.
          </Link>
        </Typography>
      </CalloutSection>
      {children}
    </Stack>
  );
}
