import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Link, useLocation } from "react-router-dom";

export function LinkTab({
  to,
  label,
  ...props
}: Readonly<{
  to: string;
  label: string;
}>) {
  const { pathname } = useLocation();

  function active<T>(active: T, inactive: T): T {
    if (pathname === to) {
      return active;
    }
    return inactive;
  }

  return (
    <Box>
      <Typography
        {...props}
        aria-selected={active(true, false)}
        color={active("textPrimary", "textSecondary")}
        variant="h6"
        component={Link}
        to={to}
        sx={{
          textDecoration: "none",
          pl: 2,
          pr: 2,
          pt: 1,
          pb: 1,
          display: "block",
          "&:hover": {
            color: "text.primary",
          },
        }}
      >
        {label}
      </Typography>
      <Box
        sx={{
          background: active("linear-gradient(135.8deg, #F59601 0%, #CA0029 98.64%)", "transparent"),
          height: 3,
        }}
      />
    </Box>
  );
}
