import { useEffect } from "react";
import { debounce } from "lodash";
import { DeepPartial, useForm } from "react-hook-form";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import InputAdornment from "@mui/material/InputAdornment";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import { AutocompleteField, AutocompleteListbox, FAPIData, Field, TIME_VALIDATION } from "@vtr/ui-library";
import {
  AIRPORT_TYPES,
  COORD_VALIDATION,
  POSITIVE_NUMBER_VALIDATION,
  REQUIRED_VALIDATION,
  getAirportCodes,
  getAirportLabels,
} from "./utils";

export function FapiForm({
  data: fapiData,
  airports = [],
  onUpdate,
  isUpdating,
}: {
  data: DeepPartial<FAPIData>;
  airports: AIRPORT_TYPES[];
  onUpdate: (data: DeepPartial<FAPIData>) => void;
  isUpdating: boolean;
}) {
  const {
    control,
    watch,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({
    mode: "all",
    defaultValues: { ...fapiData },
  });

  const onSubmit = (data: any) => {
    onUpdate(data);
  };

  const updateData = () => {
    handleSubmit(onSubmit)();
  };
  useEffect(() => {
    const subscription = watch(debounce(updateData, 1000));
    return () => subscription.unsubscribe();
  }, [watch, handleSubmit]);

  const AIRPORTS_CODES = getAirportCodes(airports);
  const AIRPORTS_LABELS = getAirportLabels(airports);

  const disabled = isSubmitting || isUpdating;

  return (
    <form data-testid="fapi-form">
      <Typography variant="subtitle1">Time, Distance and Speed</Typography>
      <Stack mt={3} spacing={3} direction={{ md: "row", xs: "column" }}>
        <Stack flex={1} spacing={3} data-testid="origin-autofield">
          <AutocompleteField
            control={control}
            name="orig.code"
            options={AIRPORTS_CODES}
            rules={{
              required: REQUIRED_VALIDATION,
            }}
            getOptionLabel={(option) => AIRPORTS_LABELS[option]}
            renderOption={(props, option) => (
              <AutocompleteListbox key={option} component="li" {...props}>
                {AIRPORTS_LABELS[option]}
              </AutocompleteListbox>
            )}
            renderInput={({ field, fieldState }) => (
              <TextField
                {...field}
                fullWidth
                disabled={disabled}
                label="Origin"
                error={Boolean(fieldState.error?.message)}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </Stack>
        <Stack flex={1} spacing={3} data-testid="destination-autofield">
          <AutocompleteField
            control={control}
            name="dest.code"
            options={AIRPORTS_CODES}
            rules={{
              required: REQUIRED_VALIDATION,
            }}
            getOptionLabel={(option) => AIRPORTS_LABELS[option]}
            renderOption={(props, option) => (
              <AutocompleteListbox key={option} component="li" {...props}>
                {AIRPORTS_LABELS[option]}
              </AutocompleteListbox>
            )}
            renderInput={({ field, fieldState }) => (
              <TextField
                {...field}
                fullWidth
                disabled={disabled}
                label="Destination"
                error={Boolean(fieldState.error?.message)}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </Stack>
      </Stack>
      <Stack mt={3} spacing={3} direction={{ md: "row", xs: "column" }}>
        <Field
          control={control}
          name="distDest"
          rules={{
            min: POSITIVE_NUMBER_VALIDATION,
            required: REQUIRED_VALIDATION,
          }}
          renderInput={({ field, fieldState }) => (
            <TextField
              {...field}
              fullWidth
              disabled={disabled}
              type="number"
              label="Distance to destination"
              error={Boolean(fieldState.error?.message)}
              helperText={fieldState.error?.message}
              InputProps={{
                endAdornment: <InputAdornment position="end">nmi</InputAdornment>,
              }}
            />
          )}
        />
      </Stack>
      <Stack mt={3} spacing={3} direction={{ md: "row", xs: "column" }}>
        <Stack flex={1} spacing={3}>
          <Field
            control={control}
            name="heading"
            rules={{
              min: POSITIVE_NUMBER_VALIDATION,
              required: REQUIRED_VALIDATION,
            }}
            renderInput={({ field, fieldState }) => (
              <TextField
                {...field}
                fullWidth
                disabled={disabled}
                label="Heading"
                type="number"
                error={Boolean(fieldState.error?.message)}
                helperText={fieldState.error?.message}
                InputProps={{
                  endAdornment: <InputAdornment position="end">deg</InputAdornment>,
                }}
              />
            )}
          />
        </Stack>
        <Stack flex={1} spacing={3}>
          <Field
            control={control}
            name="groundSpeed"
            rules={{
              min: POSITIVE_NUMBER_VALIDATION,
              required: REQUIRED_VALIDATION,
            }}
            renderInput={({ field, fieldState }) => (
              <TextField
                {...field}
                fullWidth
                disabled={disabled}
                type="number"
                label="Ground speed"
                error={Boolean(fieldState.error?.message)}
                helperText={fieldState.error?.message}
                InputProps={{
                  endAdornment: <InputAdornment position="end">kn</InputAdornment>,
                }}
              />
            )}
          />
        </Stack>
      </Stack>
      <Stack mt={3} spacing={3} direction={{ md: "row", xs: "column" }}>
        <Stack flex={1} spacing={3}>
          <Field
            control={control}
            name="timeDest"
            rules={{
              pattern: {
                value: TIME_VALIDATION,
                message: "Invalid format, the required format is: HH:MM",
              },
              required: REQUIRED_VALIDATION,
            }}
            renderInput={({ field, fieldState }) => (
              <TextField
                {...field}
                fullWidth
                disabled={disabled}
                label="Time to destination"
                error={Boolean(fieldState.error?.message)}
                helperText={fieldState.error?.message}
                InputProps={{
                  endAdornment: <InputAdornment position="end">hr</InputAdornment>,
                }}
              />
            )}
          />
        </Stack>
        <Stack flex={1} spacing={3}>
          <Field
            control={control}
            name="eta"
            rules={{
              pattern: {
                value: TIME_VALIDATION,
                message: "Invalid format, the required format is: HH:MM",
              },
              required: REQUIRED_VALIDATION,
            }}
            renderInput={({ field, fieldState }) => (
              <TextField
                {...field}
                fullWidth
                disabled={disabled}
                label="ETA"
                error={Boolean(fieldState.error?.message)}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </Stack>
      </Stack>
      <Stack mt={4} spacing={3} direction={{ md: "row", xs: "column" }}>
        <Typography variant="subtitle1">Location</Typography>
      </Stack>
      <Stack mt={3} spacing={3} direction={{ md: "row", xs: "column" }}>
        <Stack flex={1} spacing={3}>
          <Field
            control={control}
            name="altitude"
            rules={{
              min: POSITIVE_NUMBER_VALIDATION,
              required: REQUIRED_VALIDATION,
            }}
            renderInput={({ field, fieldState }) => (
              <TextField
                {...field}
                fullWidth
                disabled={disabled}
                type="number"
                label="Altitude"
                error={Boolean(fieldState.error?.message)}
                helperText={fieldState.error?.message}
                InputProps={{
                  endAdornment: <InputAdornment position="end">ft</InputAdornment>,
                }}
              />
            )}
          />
        </Stack>
        <Stack flex={1} spacing={3}>
          <Field
            control={control}
            name="lat"
            rules={{
              min: POSITIVE_NUMBER_VALIDATION,
              required: REQUIRED_VALIDATION,
              pattern: COORD_VALIDATION,
            }}
            renderInput={({ field, fieldState }) => (
              <TextField
                {...field}
                fullWidth
                disabled={disabled}
                type="number"
                label="Latitude"
                error={Boolean(fieldState.error?.message)}
                helperText={fieldState.error?.message}
                InputProps={{
                  endAdornment: <InputAdornment position="end">°</InputAdornment>,
                }}
              />
            )}
          />
        </Stack>
        <Stack flex={1} spacing={3}>
          <Field
            control={control}
            name="lon"
            rules={{
              min: POSITIVE_NUMBER_VALIDATION,
              required: REQUIRED_VALIDATION,
              pattern: COORD_VALIDATION,
            }}
            renderInput={({ field, fieldState }) => (
              <TextField
                {...field}
                fullWidth
                disabled={disabled}
                type="number"
                label="Longitude"
                error={Boolean(fieldState.error?.message)}
                helperText={fieldState.error?.message}
                InputProps={{
                  endAdornment: <InputAdornment position="end">°</InputAdornment>,
                }}
              />
            )}
          />
        </Stack>
      </Stack>
      <Stack mt={4} spacing={3} direction={{ md: "row", xs: "column" }}>
        <Typography variant="subtitle1">Aircraft Details</Typography>
      </Stack>
      <Stack mt={3} spacing={3} direction={{ md: "row", xs: "column" }}>
        <Stack flex={1} spacing={3}>
          <Field
            control={control}
            name="flightNumber"
            rules={{
              minLength: {
                message: "Minimum length is 4!",
                value: 4,
              },
              required: REQUIRED_VALIDATION,
            }}
            renderInput={({ field: { onChange, value, ...rest }, fieldState }) => (
              <TextField
                {...rest}
                fullWidth
                disabled={disabled}
                value={value?.toString().toUpperCase()}
                onChange={(e) => onChange(e.target.value.toUpperCase())}
                label="Flight number"
                error={Boolean(fieldState.error?.message)}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </Stack>
        <Stack flex={1} spacing={3}>
          <Field
            control={control}
            name="tailSign"
            rules={{
              minLength: {
                message: "Minimum length is 5!",
                value: 5,
              },
              required: REQUIRED_VALIDATION,
            }}
            renderInput={({ field: { onChange, value, ...rest }, fieldState }) => (
              <TextField
                {...rest}
                fullWidth
                disabled={disabled}
                value={value?.toString().toUpperCase()}
                onChange={(e) => onChange(e.target.value.toUpperCase())}
                label="Tail sign"
                error={Boolean(fieldState.error?.message)}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </Stack>
        <Stack flex={1} spacing={3}>
          <Field
            control={control}
            name="temperature"
            rules={{
              required: REQUIRED_VALIDATION,
            }}
            renderInput={({ field, fieldState }) => (
              <TextField
                {...field}
                fullWidth
                disabled={disabled}
                label="Temperature"
                type="number"
                error={Boolean(fieldState.error?.message)}
                helperText={fieldState.error?.message}
                InputProps={{
                  endAdornment: <InputAdornment position="end">deg</InputAdornment>,
                }}
              />
            )}
          />
        </Stack>
      </Stack>
      <Stack mt={3} spacing={3} pl={2} direction={{ md: "row", xs: "column" }}>
        <Stack flex={1} spacing={3}>
          <Field
            control={control}
            name="doorsOpen"
            renderInput={({ field }) => (
              <FormControlLabel
                sx={{
                  mr: 0,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  flexDirection: "row-reverse",
                }}
                control={<Switch disabled={disabled} checked={Boolean(field.value)} {...field} />}
                data-testid="doorsLabel"
                label={
                  <Box>
                    <FormLabel component="legend" data-testid="doorsLegend">
                      Doors
                    </FormLabel>
                    <Typography variant="caption" data-testid="doorsCaption">
                      Doors {field.value ? "Open" : "Close"}
                    </Typography>
                  </Box>
                }
              />
            )}
          />
        </Stack>
        <Stack flex={1} spacing={3}>
          <Field
            control={control}
            name="engineOff"
            renderInput={({ field }) => (
              <FormControlLabel
                sx={{
                  mr: 0,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  flexDirection: "row-reverse",
                }}
                control={
                  <Switch
                    disabled={disabled}
                    checked={!field.value}
                    onChange={(e) => field.onChange(!e.target.checked)}
                  />
                }
                data-testid="enginesLabel"
                label={
                  <Box>
                    <FormLabel component="legend" data-testid="enginesLegend">
                      Engines
                    </FormLabel>
                    <Typography variant="caption" data-testid="enginesCaption">
                      Engines {!field.value ? "On" : "Off"}
                    </Typography>
                  </Box>
                }
              />
            )}
          />
        </Stack>
        <Stack flex={1} spacing={3}>
          <Field
            control={control}
            name="arincAvailable"
            renderInput={({ field }) => (
              <FormControlLabel
                sx={{
                  mr: 0,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  flexDirection: "row-reverse",
                }}
                control={
                  <Switch
                    disabled={disabled}
                    checked={!field.value}
                    onChange={(e) => field.onChange(!e.target.checked)}
                  />
                }
                data-testid="arincAvailableLabel"
                label={
                  <Box>
                    <FormLabel component="legend" data-testid="arincAvailableLegend">
                      Arinc available
                    </FormLabel>
                    <Typography variant="caption" data-testid="arincAvailableCaption">
                      Arinc {!field.value ? "On" : "Off"}
                    </Typography>
                  </Box>
                }
              />
            )}
          />
        </Stack>
      </Stack>
      <Stack mt={3} spacing={3} pl={2} direction={{ md: "row", xs: "column" }}>
        <Stack flex={1} spacing={3}>
          <Field
            control={control}
            name="clearRoute"
            renderInput={({ field }) => (
              <FormControlLabel
                sx={{
                  mr: 0,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  flexDirection: "row-reverse",
                }}
                control={<Switch disabled={disabled} checked={Boolean(field.value)} {...field} />}
                data-testid="clearRouteLabel"
                label={
                  <>
                    <Box>
                      <FormLabel component="legend" data-testid="clearRouteLegend">
                        Clear route
                      </FormLabel>
                      <Typography variant="caption" data-testid="clearRouteCaption">
                        Clear route {field.value ? "On" : "Off"}
                      </Typography>
                    </Box>
                  </>
                }
              />
            )}
          />
        </Stack>
        <Stack flex={1} spacing={3}>
          <Field
            control={control}
            name="displayPA"
            renderInput={({ field }) => (
              <FormControlLabel
                sx={{
                  mr: 0,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  flexDirection: "row-reverse",
                }}
                data-testid="displayPALabel"
                control={<Switch disabled={disabled} checked={Boolean(field.value)} {...field} />}
                label={
                  <>
                    <Box>
                      <FormLabel component="legend" data-testid="displayPALegend">
                        Passenger announcement
                      </FormLabel>
                      <Typography variant="caption" data-testid="displayPACaption">
                        Passenger announcement {field.value ? "On" : "Off"}
                      </Typography>
                    </Box>
                  </>
                }
              />
            )}
          />
        </Stack>
        <Stack flex={1} spacing={3}></Stack>
      </Stack>
    </form>
  );
}
